

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import TimelineItem from "examples/Timeline/TimelineItem";

import logoNewspaper from "assets/images/small-logos/application-4.png";

const index = (data) => {

    const zimmetRows = data.data?.zimmetler?.map(zimmet => (
        <TimelineItem
          key={zimmet.id}
          color={zimmet.terfi_tenzil === "alindi" ? "success" : "error"}
          icon={zimmet.terfi_tenzil === "alindi" ? "unarchive" : "archive"}
          description={<span>Cihaz Adı: {zimmet.cihaz_adi} <br />Seri No: {zimmet.seri_no}</span>}
          title={`${zimmet.model} (${zimmet.marka})`}
          dateTime={zimmet.zimmet_tarih}
        />
      )) || [];
      
    

    return (
        <Grid item xs={12} md={6} xl={6}>
            <Card className="h-100">
                <SoftBox pt={3} px={3}>
                    <SoftTypography variant="h6" fontWeight="medium">
                        Zimmet geçmişi
                    </SoftTypography>
                    <SoftBox mt={1} mb={2}>
                        <SoftTypography variant="button" color="text" fontWeight="regular">
                            <SoftTypography display="inline" variant="body2" verticalAlign="middle">
                                <Icon sx={{ fontWeight: "bold", color: ({ palette: { success } }) => success.main }}>
                                    arrow_upward
                                </Icon>
                            </SoftTypography>
                            &nbsp;
                            <SoftTypography variant="button" color="text" fontWeight="medium">
                                {zimmetRows.length}
                            </SoftTypography>{" "}
                            Zimmetli Kayıtları
                        </SoftTypography>
                    </SoftBox>
                </SoftBox>
                <SoftBox p={2}>
                    {zimmetRows}
 
                </SoftBox>
            </Card>
        </Grid>
    );
}

export default index;
