import React, { useEffect, useState } from 'react';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import axios from "axios";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";
import Table from "examples/Tables/Table";

import team2 from "assets/images/team-takvim.jpg";

/* eslint-disable react/prop-types */

const ayIsimleri = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];

function Author({ image, name, email, tel }) {
  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">{name}</SoftTypography>
        {email && <SoftTypography variant="caption" color="dark">{email}</SoftTypography>}
        {tel && <SoftTypography variant="caption" color="secondary">{tel}</SoftTypography>}
      </SoftBox>
    </SoftBox>
  );
}

function index({ data }) {
  const [dataNews, setDataNews] = useState({ veriler: [] });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/inews/${data?.data?.id}`, { withCredentials: true });
        setDataNews(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [data]);

  const aylikVeriler = [];

  // Ay ve Yıl verilerini çekip formatlıyoruz
  dataNews?.veriler?.forEach(yilVerisi => {
    const yil = yilVerisi.yil;
    Object.keys(yilVerisi.aylar || {}).forEach(ay => {
      const ayVerisi = yilVerisi.aylar[ay];
      aylikVeriler.push({
        tarih: `${ay}-${yil}`,
        ay_toplam: ayVerisi?.ay_toplam || 0,
        haber_turleri: ayVerisi?.haber_turleri || {}
      });
    });
  });

  // Aylık verileri sıralıyoruz (Büyükten küçüğe)
  aylikVeriler.sort((a, b) => {
    const tarihA = new Date(a.tarih.split('-').reverse().join('-'));
    const tarihB = new Date(b.tarih.split('-').reverse().join('-'));
    return tarihB - tarihA;
  });

  const rows = [];

  // Aylık verileri tablodaki satırlara dönüştürüyoruz
  aylikVeriler.forEach(({ tarih, ay_toplam, haber_turleri }) => {
    const [ay, yil] = tarih.split("-");
    const ayname = ayIsimleri[parseInt(ay) - 1];

    // Her ay için bir satır ekliyoruz
    rows.push({
      Adı: <Author image={team2} name={`${ayname} ${yil} Tarihi`} />,
      Kategori: <SoftBadge variant="gradient" badgeContent="TÜMÜ" color="dark" size="xs" container />,
      "Aylık Performans": <SoftTypography variant="caption" color="secondary" fontWeight="medium">{ay_toplam}</SoftTypography>,
      Ay: <SoftTypography variant="caption" color="secondary" fontWeight="medium">{ayname}</SoftTypography>,
      Yıl: <SoftTypography variant="caption" color="secondary" fontWeight="medium">{yil}</SoftTypography>,
    });

    // Haber türlerine göre ek veriler
    Object.keys(haber_turleri).forEach(item => {
      rows.push({
        Adı: <SoftTypography variant="caption" color="secondary" fontWeight="medium" px={3}>Aylık Veriler</SoftTypography>,
        Kategori: <SoftBadge variant="gradient" badgeContent={item} color="info" size="xs" container />,
        "Aylık Performans": <SoftTypography variant="caption" color="secondary" fontWeight="medium">{haber_turleri[item]}</SoftTypography>,
        Ay: <SoftTypography variant="caption" color="secondary" fontWeight="medium">{ayname}</SoftTypography>,
        Yıl: <SoftTypography variant="caption" color="secondary" fontWeight="medium">{yil}</SoftTypography>,
      });
    });
  });

  const columns = [
    { name: "Adı", align: "left", exportableField: "field_name" },
    { name: "Kategori", align: "left", exportableField: "field_kategori", searchable: true, searchField: ["field_kategori"] },
    { name: "Aylık Performans", align: "center" },
    { name: "Ay", align: "center", searchable: true, searchField: ["field_ay"] },
    { name: "Yıl", align: "center", searchable: true, searchField: ["field_yil"] },
  ];

  return (
    <Grid item xs={12} md={12} xl={12}>
      <Card>
        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          <SoftTypography variant="h6">Aylık Performans Tablosu</SoftTypography>
        </SoftBox>
        <SoftBox sx={{
          "& .MuiTableRow-root:not(:last-child)": {
            "& td": {
              borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                `${borderWidth[1]} solid ${borderColor}`,
            },
          },
        }}>
          <Table columns={columns} rows={rows} />
        </SoftBox>
      </Card>
    </Grid>
  );
}

export default index;
