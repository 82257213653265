// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";

// Soft UI Dashboard React examples
import TimelineItem from "examples/Timeline/TimelineItem";

const Index = ({ data }) => {
  try {
    // `sikayet_eden_disiplinler` verisinin varlığına göre null kontrolü yapıyoruz
    const terfiRows = data?.sikayet_eden_disiplinler?.map((terfi) => (
      <TimelineItem
        key={terfi.id}
        color="info"
        icon="gavel"
        description={
          <span>
            Sorumlu Kişi: {`${terfi?.sorumlu_kisi?.name || ""} ${terfi?.sorumlu_kisi?.surname || ""}`} ({terfi?.sorumlu_kisi?.trt_arabi_unvan}) <br />
            Dosya No: {terfi?.dosya_isim} <br />
            <SoftBadge
              variant="gradient"
              badgeContent={terfi?.durum}
              color="default"
              size="xs"
              container
            />
            <br />
            <Icon sx={{ fontWeight: "bold", color: "error" }}>label_important</Icon> Açıklama: {terfi?.aciklama} <br />
            {terfi?.sonuc ? (
              <>
                <br />
                <SoftBadge
                  variant="gradient"
                  badgeContent="KARAR"
                  color="error"
                  size="xs"
                  container
                />
                <br /> {terfi.sonuc}
              </>
            ) : (
              "ChatBubble"
            )}
          </span>
        }
        title={`${terfi?.sikayet_edilen?.[0]?.name || ""} (${terfi?.sikayet_edilen?.[0]?.trt_arabi_unvan || ""})`}
        dateTime={`${terfi?.acilma_tarihi || ""} - ${terfi?.kapanma_tarihi || ""}`}
      />
    )) || [];

    return (
      <Grid item xs={12} md={6} xl={6}>
        <Card className="h-100">
          <SoftBox pt={3} px={3}>
            <SoftTypography variant="h6" fontWeight="medium">
              Şikayetçi Olunan Disiplin ({terfiRows.length})
            </SoftTypography>
            <SoftBox mt={1} mb={2}>
              <SoftTypography variant="button" color="text" fontWeight="regular">
                <SoftTypography display="inline" variant="body2" verticalAlign="middle">
                  <Icon sx={{ fontWeight: "bold", color: "error" }}>label_important</Icon>
                </SoftTypography>
                &nbsp;
                <SoftTypography variant="button" color="text" fontWeight="medium">
                  {data?.name || "Bilinmiyor"}
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <SoftBox p={2}>{terfiRows}</SoftBox>
        </Card>
      </Grid>
    );
  } catch (error) {
    return (
      <Grid item xs={12} md={12} xl={12}>
        <SoftBox pt={3} px={3}>
          <SoftTypography variant="h2" fontWeight="medium">
            Yetkilendirilmemiş Giriş
          </SoftTypography>
        </SoftBox>
      </Grid>
    );
  }
};

export default Index;
