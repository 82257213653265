import React from 'react'

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Table from "examples/Tables/Table";

import Icon from "@mui/material/Icon";
import Link from '@mui/material/Link';

// Soft UI Dashboard React examples
import TimelineItem from "examples/Timeline/TimelineItem";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

// Soft UI Dashboard React examples
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";

import team2 from "assets/images/team-doc.jpg";

import Header from "layouts/performans-list/components/Header";
/* eslint-disable react/prop-types */

import Aylik from "./Aylik";
//import UcAylik from "./3Aylik";
//import AltiAylik from "./6Aylik";
import Yillik from "./Yillik";
//import Genel from "./Genel";
import PerformansCard6Aylik from "examples/PerformansCards/PerformansCard6Aylik";
import PerformansCardYillik from "examples/PerformansCards/PerformansCardYillik";
import PerformansCardGenel from "examples/PerformansCards/PerformansCardGenel";

import { useState, useEffect } from "react";

const getMonthName = (monthNumber) => {
    const months = [
        'Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran',
        'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'
    ];
    return months[monthNumber - 1];
}

function Author({ image, name }) {
    return (
        <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
            <SoftBox mr={2}>
                <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
            </SoftBox>
            <SoftBox display="flex" flexDirection="column">
                <SoftTypography variant="button" fontWeight="medium">
                    {name}
                </SoftTypography>
            </SoftBox>
        </SoftBox>
    );
}


const truncatedTextStyle = {
    width: '300px',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    height: '20px',
    WebkitBoxOrient: 'vertical'
};



const index = (data) => {





    let notRows = []


    notRows.push(
        <Grid item xs={12} md={6} xl={6}  >
            <Card className="h-100" sx={{ background: "linear-gradient(310deg, #141727, #3a416f)" }}>
                <SoftBox pt={3} px={3}>
                    <SoftTypography variant="h6" fontWeight="bold" color="light">
                        TRT Performans Rapor Oluşturucu
                    </SoftTypography>
                </SoftBox>
                <SoftBox p={2}>
                    <TimelineItem

                        color="dark"
                        icon="get_app"
                        description="Özelleştirilmiş, kişisel bilgilerinizi içeren Özgeçmiş örneğimizi buradan inceleyebilirsiniz"
                        title={
                            <Link color="#fff" href={`/rapor/${data.data.id}/aylik`}  >
                                TRT AYLIK RAPOR <strong>OLUŞTUR</strong>
                            </Link>
                        }
                        

                    />
                </SoftBox>
            </Card>
        </Grid>);

    notRows.push(
        <Grid item xs={12} md={6} xl={6}  >
            <Card className="h-100" sx={{ background: "linear-gradient(310deg, #141727, #3a416f)" }}>
                <SoftBox pt={3} px={3}>
                    <SoftTypography variant="h6" fontWeight="bold" color="light">
                    TRT Performans Rapor Oluşturucu
                    </SoftTypography>
                </SoftBox>
                <SoftBox p={2}>
                    <TimelineItem

                        color="dark"
                        icon="get_app"
                        description="Kurumumuzun profil bilgilerini içeren detaylı rapor örneğimizi buradan gözden geçirebilirsiniz"
                        title={
                            <Link color="#fff" href={`/rapor/${data.data.id}/yillik`}  >
                                TRT YILLIK RAPOR <strong>OLUŞTUR</strong>
                            </Link>
                        }

                    />
                </SoftBox>
            </Card>
        </Grid>);


    notRows.push(data.data?.dosyalar?.map(not => (
        <>
            <Grid item xs={12} md={6} xl={6} key={not.id} >


                <Card className="h-100" >
                    <SoftBox pt={3} px={3}>
                        <SoftTypography variant="h6" fontWeight="medium">
                            {not.title}
                        </SoftTypography>
                        <SoftBox mt={1} mb={2}>
                            <SoftTypography variant="button" color="text" fontWeight="regular">
                                <SoftTypography display="inline" variant="body2" verticalAlign="middle">
                                    <Icon sx={{ fontWeight: "bold", color: "error" }}>
                                        label_important
                                    </Icon>
                                </SoftTypography>
                                &nbsp;
                                <SoftTypography variant="button" color="text" fontWeight="medium">

                                </SoftTypography>{" "}
                                {not.yuklenme_tarihi}
                            </SoftTypography>
                        </SoftBox>
                    </SoftBox>
                    <SoftBox p={2}>

                        <TimelineItem

                            color={not.terfi_tenzil === "alindi" ? "primary" : "info"}
                            icon={not.terfi_tenzil === "alindi" ? "ChatBubble" : "get_app"}
                            description={not.notu}
                            title={
                                <Link href={`${not.dosya}`} download>
                                    {not.dosya}
                                </Link>
                            }
                            dateTime={not.zimmet_tarih}
                        />

                    </SoftBox>
                </Card>
            </Grid>
  
        </>
    ))
    );



    const [tabIndex, setTabIndex] = useState(0);

    const onTabChange = (index) => {
        setTabIndex(index);
    }



    const { size } = typography;

    const ozelIslerRows = data.data?.gorevler?.flatMap(gorev => 
        gorev.yapilan_ozel_isler?.map(ozelIs => ({
          "Başlık": <Author image={team2} name={ozelIs.title} />,
          "Tür": <SoftTypography variant="caption" color="text" fontWeight="medium">{ozelIs.tur}</SoftTypography>,
          "Ay": <SoftTypography variant="caption" color="text" fontWeight="medium">{getMonthName(gorev.ay)}</SoftTypography>,
          "Yıl": <SoftTypography variant="caption" color="text" fontWeight="medium">{gorev.yil}</SoftTypography>
        })) || []
      ) || [];
      


    const projectsTableData = {
        columns: [
            { name: "Başlık", align: "left" },
            { name: "Tür", align: "Center" },
            { name: "Ay", align: "left" },
            { name: "Yıl", align: "left" },

        ],

        rows: [
            ...ozelIslerRows,

        ],
    };







    const gradientLineChartData = {
        labels: ["Oca", "Şub", "Mar", "Nis", "May", "Haz", "Tem", "Ağu", "Eyl", "Eki", "Kas", "Ara"],
        datasets: [
            {
                label: "Yapılması Gereken İşler",
                color: "info",
                data: [20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 0],
            },
            {
                label: "Yapılan İş Sayısı",
                color: "dark",
                data: [30, 37, 40, 14, 29, 29, 34, 23, 20, 34, 23, 20, 0],
            },
        ],
    };

    return (
        <>
        {notRows}
            
            <Grid item xs={12} md={12} xl={12}>
                <Header onChange={e => onTabChange(e)} data={data} />
            </Grid>
            <Grid item xs={12} md={12} xl={12}>
                <SoftBox mt={0} mb={3}>
                    <Grid container spacing={3}>

                        {tabIndex === 0 && (
                            <>
                                <Aylik data={data.data} />
                            </>
                        )}
                    

                        {tabIndex === 1 && (
                            <>
                                <Yillik data={data.data}  />
                            </>
                        )}



                    </Grid>
                </SoftBox>
            </Grid>
        </>
    )
}

export default index
