import React from 'react'

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedinIcon from "@mui/icons-material/LinkedIn";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";

const index = (data) => {
    console.log(data);
    const safeValue = (value) => value === null ? "-" : value;
    
    return (
        <Grid item xs={12} md={6} xl={4}>
            <ProfileInfoCard
              title="İletişim Bilgileri"
              description="İletişim bilgileri detayları"
              info={{
                "Telefon Numarası": safeValue(data.data.kisisel_cep_no),
                "İş Numarası": safeValue(data.data.is_cep_no),
                "Kişisel E-posta": safeValue(data.data.kisisel_mail),
                "TRT E-posta": safeValue(data.data.trt_mail),
                "TRT Arabi E-posta": safeValue(data.data.trt_arabi_mail),
                
                "Acil Durum Tel No": safeValue(data.data.acil_durum_tel_no),
                "Ev adresi": safeValue(data.data.ev_adresi),

          
                

              }}
              social={[
                {
                  link: "https://www.facebook.com/" +safeValue(data.data.facebook),
                  icon: <FacebookIcon />,
                  color: "facebook",
                },
                {
                  link: "https://twitter.com/"+safeValue(data.data.x),
                  icon: <TwitterIcon />,
                  color: "twitter",
                },
                {
                  link: "https://www.instagram.com/"+safeValue(data.data.instagram),
                  icon: <InstagramIcon />,
                  color: "instagram",
                },
                {
                  link: "https://www.instagram.com/"+safeValue(data.data.linkedin),
                  icon: <LinkedinIcon />,
                  color: "instagram",
                },
              ]}
              action={{ route: "/profile/edit", tooltip: "Profili Düzenle" }}
            />
          </Grid>
    )
}

export default index
