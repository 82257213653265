import ProfileCardOzet from "examples/ProfileCards/ProfileCardOzet";

import PermissionNavbar from "examples/Navbars/PermissionNavbar";

import { useParams, useNavigate } from 'react-router-dom';

import { useState, useEffect } from "react";
import axios from "axios"


function Overview() {

    const { id } = useParams();
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`${window.BASE_URL}/profil/ozet/${id}?format=json`, { withCredentials: true });
                setData(response.data);
            } catch (error) {
                setData("error");
            }
        };

        fetchUserData();
    }, [id]);


    if (data === "error") {
        return <PermissionNavbar />;
    }

    return (
        <>
            <ProfileCardOzet data={data} />
         
        </>
    );
}

export default Overview;