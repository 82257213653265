import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from 'react-router-dom';
/* eslint-disable react/prop-types */

// MUI components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard components
import SoftAvatar from "components/SoftAvatar";
import SoftBox from "components/SoftBox";
import SoftBadge from "components/SoftBadge";
import SoftDatePicker from "components/SoftDatePicker";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import SoftButton from "components/SoftButton";
import Header from "layouts/form/header";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import PermissionNavbar from "examples/Navbars/PermissionNavbarPage";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function DynamicForm({ operation = "create" }) {

    const isDisable = operation == "delete" ? true : false


    const { model_name, id } = useParams();  // id'yi kontrol etmek için ekledik
    const navigate = useNavigate();

    const [formFields, setFormFields] = useState({});
    const [formData, setFormData] = useState({});

    const [errorStatus, setErrorStatus] = useState(false)

    useEffect(() => {
        const fetchModelFields = async () => {
            try {
                let fieldsResponse;
        
                // `id` varsa detay isteğini, yoksa yalnızca alan isteğini atıyoruz
                if (id) {
                    fieldsResponse = await axios.get(`${window.BASE_URL}/model-fields/${model_name}/${id}`, { withCredentials: true });
                } else {
                    fieldsResponse = await axios.get(`${window.BASE_URL}/model-fields/${model_name}`, { withCredentials: true });
                }
        
                const fields = fieldsResponse.data.fields;
                setFormFields(fields);
        
                // `formData`yı başlatma işlemi
                const initialData = {};
                Object.values(fields).flat().forEach(field => {
                    // Önce `value` varsa, yoksa `default` değerini alıyoruz.
                    const fieldValue = field.value !== undefined && field.value !== null ? field.value : field.default;
                    initialData[field.name] = fieldValue !== null ? fieldValue : (field.type === 'BooleanField' ? false : '');
                });
                setFormData(initialData); // `formData` güncellenmiş değerlerle ayarlanıyor
        
            } catch (error) {
                setErrorStatus(true);
                console.error("Error fetching model fields:", error);
            }
        };
    
        fetchModelFields();
    }, [model_name, id]);
    
    


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSelectChange = (selected, fieldName) => {
        setFormData(prevData => ({
            ...prevData,
            [fieldName]: selected ? selected.value : ""
        }));
    };



    const handleSwitchChange = (name) => {
        setFormData({ ...formData, [name]: !formData[name] });
    };

    const handleDateChange = (date, name) => {
        setFormData({ ...formData, [name]: date });
    };

    const handleSubmit = async () => {
        try {
            // formData'nın bir kopyasını alıp ForeignKey alanlarını düzenliyoruz
            const processedData = Object.keys(formData).reduce((acc, key) => {
                const value = formData[key];
                if (typeof value === "object" && value !== null && "value" in value) {
                    // Eğer değer bir obje ise ve 'value' anahtarı varsa, yalnızca id veya value'yu al
                    acc[key] = value.value;
                } else {
                    acc[key] = value;
                }
                return acc;
            }, {});
    
            // API'ye gönderilecek veriyi `processedData` ile değiştiriyoruz
            if (operation === "delete" && id) {
                const response = await axios.delete(`${window.BASE_URL}/model-fields-delete/${model_name}/${id}`, { withCredentials: true });
                if (response.status === 200) {
                    navigate(-1);
                }
            } else if (operation === "edit" && id) {
                const response = await axios.post(`${window.BASE_URL}/model-fields-edit/${model_name}/${id}`, processedData, { withCredentials: true });
                if (response.status === 200) {
                    navigate(-1);
                }
            } else {
                const response = await axios.post(`${window.BASE_URL}/model-fields-save/${model_name}`, processedData, { withCredentials: true });
                if (response.status === 200) {
                    navigate(-1);
                }
            }
            
        } catch (error) {
            alert(error);
            console.error("Error submitting form:", error);
        }
    };
    


    const NewTarget = ({ name, image, color = "secondary", icon = "bookmark", email, tel, style }) => (
        <SoftBadge style={style} variant="variant" badgeContent={
            <SoftBox display="flex" alignItems="center" px={0} py={0.5} style={{ width: "auto" }}>
                <SoftBox mr={2}>
                    <SoftAvatar src={image} size="sm" icon={icon} bgColor={color} variant="rounded" iconSize="16px !important" />
                </SoftBox>
                <SoftBox display="flex" flexDirection="column" alignItems="flex-start">
                    <SoftTypography display="inline" variant="body2" verticalAlign="middle" fontSize="14px" fontWeight="medium">{name}</SoftTypography>
                    <SoftTypography variant="caption" color="text" fontWeight="medium">
                        {email}
                    </SoftTypography>
                    <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                        {tel}
                    </SoftTypography>
                </SoftBox>

            </SoftBox>
        } color="light" size="xl" container />
    );

    const renderFormField = (field) => {
        if (!field || !field.name) return null;

        const label = field.verbose_name || field.name.charAt(0).toUpperCase() + field.name.slice(1);

        if (field.type === "ForeignKey") {
            // `selectedOption` için formData ve field.value kontrolü
            let selectedOption = field.options.find(option => 
                option.value === formData[field.name]
            );
        
            // Eğer `selectedOption` bulunamazsa, `field.value`'i kullanarak `displayOption` ayarla
            const displayOption = selectedOption
                ? {
                    value: selectedOption.value,
                    label: (
                        <NewTarget
                            name={selectedOption.display || selectedOption.value}
                            email={selectedOption.detail}
                            image={window.BASE_URL + "/" + selectedOption.photo}
                        />
                    ),
                    search: selectedOption.display || selectedOption.value
                }
                : field.value // Eğer `field.value` opsiyonlarda yoksa onu `NewTarget` olarak göster
                ? {
                    value: field.value.value,
                    label: (
                        <NewTarget
                            name={field.value.display || field.value.value}
                            email={field.value.detail}
                            image={window.BASE_URL + "/" + field.value.photo}
                        />
                    ),
                    search: field.value.display || field.value.value
                }
                : null;
        
            return (
                <Grid item xs={12} key={field.name}>
                    <SoftBox mb={1}>
                        <SoftTypography variant="caption" fontWeight={field.required ? "bold" : "thin"}>
                            {label}
                        </SoftTypography>
                        <SoftSelect
                            placeholder={field.default}
                            value={displayOption}
                            options={field.options.map(option => ({
                                value: option.value,
                                label: (
                                    <NewTarget
                                        name={option.display || option.value}
                                        email={option.detail}
                                        image={window.BASE_URL + "/" + option.photo}
                                    />
                                ),
                                search: option.display || option.value
                            }))}
                            size="large"
                            onChange={(selected) => {
                                setFormData(prevData => ({
                                    ...prevData,
                                    [field.name]: selected ? selected.value : ""
                                }));
                            }}
                            required={field.required}
                            disabled={isDisable}
                        />
                    </SoftBox>
                </Grid>
            );
        }        
        

        // Choice Alanları
        if (field.choices && field.choices.length > 0) {
            const selectedChoice = field.choices.find(choice => choice.value === formData[field.name]) || null;

            return (
                <Grid item xs={12} key={field.name}>
                    <SoftBox mb={1}>
                        <SoftTypography variant="caption" fontWeight={field.required ? "bold" : "thin"}>{label}</SoftTypography>
                        <SoftSelect
                            placeholder={field.default}
                            value={selectedChoice ? { value: selectedChoice.value, label: selectedChoice.display || selectedChoice.value } : null}
                            options={field.choices.map(choice => ({
                                value: choice.value,
                                label: choice.display || choice.value
                            }))}
                            onChange={(selected) => {
                                setFormData(prevData => ({
                                    ...prevData,
                                    [field.name]: selected ? selected.value : ""
                                }));
                            }}
                            required={field.required}
                            disabled={isDisable}
                        />
                    </SoftBox>
                </Grid>
            );
        }

        // DateField Alanları
        if (field.type === "DateField") {
            return (
                <Grid item xs={12} key={field.name}>
                    <SoftBox mb={1}>
                        <SoftTypography variant="caption" fontWeight={field.required ? "bold" : "thin"}>{label}</SoftTypography>
                        <SoftDatePicker
                            name={field.name}
                            placeholder={field.default}
                            value={formData[field.name] || null}
                            onChange={(date) => setFormData(prevData => ({
                                ...prevData,
                                [field.name]: date
                            }))}
                            required={field.required}
                            disabled={isDisable}
                        />
                    </SoftBox>
                </Grid>
            );
        }

        // Diğer input alanları
        switch (field.type) {
            case "CharField":
            case "TextField":
            case "PositiveIntegerField":
                return (
                    <Grid item xs={12} key={field.name}>
                        <SoftBox mb={1}>
                            <SoftTypography variant="caption" fontWeight={field.required ? "bold" : "thin"}>{label}</SoftTypography>
                            <SoftInput
                                name={field.name}
                                placeholder={field.default}
                                value={formData[field.name] || ''}
                                onChange={handleInputChange}
                                required={field.required}
                                multiline={field.type === "TextField"}
                                rows={field.type === "TextField" ? 4 : 1}
                                disabled={isDisable}
                            />
                        </SoftBox>
                    </Grid>
                );
            case "BooleanField":
                return (
                    <Grid item xs={12} key={field.name}>
                        <SoftBox display="flex" alignItems="center" mb={1}>
                            <SoftTypography variant="caption" fontWeight="bold">{label}</SoftTypography>
                            <Switch
                                checked={formData[field.name] || false}
                                onChange={() => handleSwitchChange(field.name)}
                                disabled={isDisable}
                            />
                        </SoftBox>
                    </Grid>
                );
            case "DecimalField":
                return (
                    <Grid item xs={12} key={field.name}>
                        <SoftBox mb={1}>
                            <SoftTypography variant="caption" fontWeight={field.required ? "bold" : "thin"}>{label}</SoftTypography>
                            <SoftInput
                                name={field.name}
                                type="number"
                                step="0.01"
                                placeholder={field.default}
                                value={formData[field.name] || ''}
                                onChange={handleInputChange}
                                required={field.required}
                                disabled={isDisable}
                            />
                        </SoftBox>
                    </Grid>
                );
            default:
                return null;
        }
    };


    if (errorStatus) {
        return <PermissionNavbar />;
    }


    return (
        <DashboardLayout>

            <Header operation={operation} />
            <SoftBox mt={3} mb={4} ml={0} style={{ width: "100%" }}>
                <Grid container spacing={3} justifyContent="center">
                    {Object.keys(formFields).map((category) => (
                        <Grid item xs={12} lg={9} key={category}>
                            <Card>
                                <SoftBox p={3} pb={1}>
                                    <SoftTypography variant="h6" fontWeight="medium">
                                        {category}
                                    </SoftTypography>
                                </SoftBox>
                                <Divider />
                                <SoftBox p={3} pt={0} pb={3}>
                                    <Grid container spacing={3}>
                                        {Array.isArray(formFields[category])
                                            ? formFields[category].map((field) => renderFormField(field))
                                            : Object.values(formFields[category]).map((field) => renderFormField(field))}
                                    </Grid>
                                </SoftBox>
                            </Card>
                        </Grid>
                    ))}
                    <Grid item xs={12} lg={9} >
                        <Card id="kaydet" style={{ background: "linear-gradient(45deg, #f8f8f8, #ffffff17)" }}>
                            <SoftBox p={3} lineHeight={1}>
                                <SoftBox mb={1}>
                                    <SoftTypography variant="h5">{operation == "delete" ? "Kayıdı Sil" : operation == "create" ? "Kayıt Oluştur" : "Kayıt Düzenle"}</SoftTypography>
                                </SoftBox>
                                <SoftTypography variant="button" color="text" fontWeight="regular">
                                    {operation == "delete" ? "Bu sekmede görüntülenen kayıt silme işlemini yapabilirsiniz." :
                                        "Bu sekmede yaptığınız değişiklikleri ve düzenlemeleri kaydedebilirsiniz."}
                                </SoftTypography>
                            </SoftBox>
                            <SoftBox pb={3} px={3} display="flex" justifyContent="space-between" alignItems={{ xs: "flex-start", sm: "center" }} flexDirection={{ xs: "column", sm: "row" }}>
                                <SoftBox display="flex" alignItems="center" mb={{ xs: 3, sm: 0 }}>

                                </SoftBox>
                                <SoftBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>

                                    <SoftBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                                        <SoftButton
                                            variant="gradient"
                                            color={operation == "delete" ? "error" : "info"}
                                            onClick={handleSubmit}
                                        >
                                            {operation == "delete" ? "Sil" : "Kaydet"}
                                        </SoftButton>

                                    </SoftBox>
                                </SoftBox>
                            </SoftBox>
                        </Card>
                    </Grid>
                </Grid>

            </SoftBox>
        </DashboardLayout>
    );
}

export default DynamicForm;
