import { useState, useEffect, useMemo, Suspense, lazy } from "react";
import { Routes, Route, Navigate, useLocation, useParams, useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import { store } from './store';
import { setIsLoggedIn, setUserId, setUserRole, setUsername, setUserKanal, setUserPhoto, setTasks } from 'store/actions/authActions';
import ReactLoading from "react-loading";
import SoftBox from "components/SoftBox";
import SoftBadge from "components/SoftBadge";
import brand from "assets/images/TRT_blue_logo.png";
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { useSoftUIController, setMiniSidenav, setOpenConfigurator } from "context";
import { getFilteredRoutes } from './routes';

// Lazy load bileşenler
const Sidenav = lazy(() => import("examples/Sidenav"));
const Configurator = lazy(() => import("examples/Configurator"));

export default function App() {

  const noScrollbarStyle = {
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
  };

  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const userId = useSelector(state => state.auth.userId);
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const dispatchRedux = useDispatch();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // RTL için cache oluşturma
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });
    setRtlCache(cacheRtl);
  }, []);

  // Scroll bar gizleme kodu (eksiksiz)
  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    const styleSheet = document.createElement('style');
    styleSheet.type = 'text/css';
    styleSheet.innerText = ' div::-webkit-scrollbar { width: 0px; }';
    document.head.appendChild(styleSheet);
    return () => {
      document.body.style.overflow = originalStyle;
      document.head.removeChild(styleSheet);
    };
  }, []);

  // API çağrısı: kullanıcı kimliği ve izinleri çekme (optimize edilmiş)
  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const [userResponse, permissionsResponse, tasksResponse] = await Promise.all([
          axios.get(`${window.BASE_URL}/api/check_user_logged_in?format=json`, { withCredentials: true }),
          axios.get(`${window.BASE_URL}/permissions?format=json`, { withCredentials: true }),
          axios.get(`${window.BASE_URL}/tasks/count?format=json`, { withCredentials: true })
        ]);

        const userData = userResponse.data;
        store.dispatch(setUsername(userData.name));
        store.dispatch(setUserRole(userData.role));
        store.dispatch(setUserKanal(userData.kanal_choice));
        store.dispatch(setUserPhoto(userData.photo));
        store.dispatch(setUserId(userData.id));
        store.dispatch(setIsLoggedIn(true));

        const taskData = tasksResponse.data;
        store.dispatch(setTasks({"misdata_count":taskData.misdata_count,"puanlama_count":taskData.puanlama_count,"gorevlendirme_count":taskData.gorevlendirme_count,"birtday_count": taskData.birtday_count}));

        setData(permissionsResponse.data);
        setIsAuthenticated(true);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // Sidenav açılma/kapama işlemleri (optimize edilmiş)
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  const getRoutes = (allRoutes) => {
    const routesArray = [];
    allRoutes.forEach(route => {
      if (route.children) {
        routesArray.push(...getRoutes(route.children));
      }
      if (route.route) {
        routesArray.push(
          <Route exact path={route.route} element={route.component} key={route.key} />
        );
      }
    });
    return routesArray;
  };

  // Yüklenme sırasında loading ekranı
  if (isLoading) {
    return (
      <div style={{ background: "#f8f9fa", textAlign: "center", width: "100%", height: "100%", position: "fixed", display:"flex", justifyContent:"center", alignItems:"center" }}>
        <ReactLoading type="bubbles" color="#1398e6" height={100} width={50} />
      </div>
    );
  }

  // Kullanıcı kimliği kontrolü
  if (!isAuthenticated && !location.pathname.startsWith('/authentication')) {
    return <Navigate to="/authentication/sign-in" />;
  } else if (isAuthenticated && location.pathname.startsWith('/authentication')) {
    return <Navigate to="/kurum/anasayfa" />;
  }

  const configsButton = (
    <SoftBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="default" color="inherit">settings</Icon>
    </SoftBox>
  );

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={themeRTL}>
        <CssBaseline />
        <div style={noScrollbarStyle}>
          {layout === "dashboard" && (
            <>
              <Suspense fallback={<div>Loading...</div>}>
                <Sidenav
                  color={sidenavColor}
                  brand={brand}
                  brandName=" Yönetim -"
                  routes={getFilteredRoutes(data)}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                />
              </Suspense>
            </>
          )}
          {layout === "vr" && <Configurator />}
          <Routes>{getRoutes(getFilteredRoutes(data))}</Routes>
        </div>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div style={noScrollbarStyle}>
        {layout === "dashboard" && (
          <>
            <Suspense fallback={<div>Loading...</div>}>
              <Sidenav
                color={sidenavColor}
                brand={brand}
                brandName=" Yönetim +"
                routes={getFilteredRoutes(data)}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
            </Suspense>
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>{getRoutes(getFilteredRoutes(data))}</Routes>
      </div>
    </ThemeProvider>
  );
}
