


import axios from "axios"

import React, { useEffect, useState } from 'react';

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Table from "examples/Tables/Table";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import HeaderTitle from "layouts/headers/NormalHeader"
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";

// Dashboard layout components
import wavesWhite from "assets/images/shapes/waves-white.svg";
import rocketWhite from "assets/images/illustrations/network-trt.png";
import curved0 from "assets/images/curved-images/curved-empty.png"

import { Divider } from "@mui/material";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import gradientLineChartData from "layouts/dashboard/data/gradientLineChartData";

/* eslint-disable react/prop-types */

function Dashboard() {
    const { size } = typography;
    let { chart, items } = reportsBarChartData;


    function Author({ id, image, name, email, tel, status }) {
        return (
            <a href={`/program/${id}`}>
                <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
                    <SoftBox mr={2}>
                        <SoftAvatar src={""} size="xl" variant="rounded" icon="description" bgColor={status === "KAPALI" ? "dark" : "secondary"} iconSize="50px !important" iconColor="#aaa !important" />
                    </SoftBox>
                    <SoftBox display="flex" flexDirection="column">
                        <SoftTypography variant="button" fontWeight="medium">
                            {name}
                        </SoftTypography>
                        <SoftTypography variant="caption" color="dark">
                            {email}
                        </SoftTypography>
                        <SoftTypography variant="caption" color="secondary">
                            {tel}
                        </SoftTypography>
                    </SoftBox>
                </SoftBox>
            </a>
        );
    }


    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${window.BASE_URL}/birtday/count`, {
                    withCredentials: true
                });
                setData(response.data);
            } catch (error) {
                console.log(error);

            }
        };

        fetchData();
    }, []);

    let dataHeader1 = {
        "title": "Memnuniyet Anketleri",
        "line1": "Çalışan Kurum Değerlendirmeleri",
        "image": curved0,
        "color": "info"
    }

    const projectsTableData = {
        columns: [
            { name: "Form Adı", align: "left", searchable: true, },
            { name: "Doldurma Tarihi", align: "left", searchable: true, },
            { name: "Puanı", align: "left", searchable: true, },
        ],
        rows: [{
            "Form Adı": <Author id={"item.id"} name={"item?.baslik"} image={window.BASE_URL + "/" + "item.goruntu"} tel={"item.acilis_tarihi"} status={"item.durum"} />,
            "Doldurma Tarihi": <SoftTypography variant="caption" color="text" fontWeight="medium">
                {"item"}</SoftTypography>,
            "Puanı": <SoftTypography variant="caption" color="text" fontWeight="medium">
                {"item"}</SoftTypography>,
        }]
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>

                <SoftBox mb={3}>
                    <Grid container spacing={3} justifyContent={"center"}>
                        <Grid item xs={12} lg={10}>
                            <HeaderTitle data={dataHeader1} />
                            <Divider />
                            <Grid container spacing={3} justifyContent={"center"}>
                                <Grid item xs={12} sm={4} xl={3.5}>
                                    <MiniStatisticsCard
                                        title={{ text: "Toplam Doldurulan" }}
                                        count={data.yesterday_count}
                                        percentage={{ color: "light", text: "" }}
                                        icon={{ color: "info", component: "drafts" }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} xl={3.5}>
                                    <MiniStatisticsCard
                                        title={{ text: "Aktif Formlar" }}
                                        count={data.today_count}
                                        percentage={{ color: "light", text: "" }}
                                        icon={{ color: "info", component: "description" }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} xl={3.5}>
                                    <MiniStatisticsCard
                                        title={{ text: "Genel Skorun" }}
                                        count={data.tomorrow_count}
                                        percentage={{ color: "light", text: "" }}
                                        icon={{ color: "dark", component: "star" }}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item xs={12} lg={10} mt={2}>
                            <Card sx={{ background: "#f8f9fa", boxShadow: "none" }}>
                                <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3} pb={0}>
                                    <SoftTypography variant="h6">Destek Talepleri</SoftTypography>
                                </SoftBox>
                                <SoftBox
                                    sx={{
                                        "& .MuiTableRow-root:not(:last-child)": {
                                            "& td": {
                                                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                                    `${borderWidth[1]} solid ${borderColor}`,
                                            },
                                        },
                                    }}
                                >
                                    <Table style={{ background: "#f8f9fa" }} columns={projectsTableData.columns} rows={projectsTableData.rows} />
                                </SoftBox>
                            </Card>

                        </Grid>

                    </Grid>
                </SoftBox>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Dashboard;
