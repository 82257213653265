

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import TimelineItem from "examples/Timeline/TimelineItem";


function createTimelineItems(branch,role) {
    let items = [];
    
    // Öğe için TimelineItem oluştur
    if(branch.Sorumlu_kisi && branch.Sorumlu_kisi.name){
    items.push(
      <TimelineItem
        key={branch.id}
        color={branch.Yetkili_birim_adi !== "-" ? "info" : "light"}
        icon="subdirectory_arrow_right"
        title={(branch.Yetkili_birim_adi !== "-" ? branch.Yetkili_birim_adi : role).toUpperCase()}
        dateTime={branch.Sorumlu_kisi.name + " "+ branch.Sorumlu_kisi.surname}
      />
    );
    }
    
    // Eğer ust_birim öğesi varsa, onun için de TimelineItem oluştur
    if (branch.ust_birim) {
      items = items.concat(createTimelineItems(branch.ust_birim));
    }
  
    return items;
  }

const index = (data) => {

    try {
        const terfiRows1 = data.data.branchler.flatMap(branch => createTimelineItems(branch,data.data.trt_arabi_unvan));

        let terfiRows = data.data.terfiler.map(terfi => (
            <TimelineItem
                key={terfi.id}
                color={terfi.terfi_tenzil === "Terfi" ? "success" : "error"}
                icon={terfi.terfi_tenzil === "Terfi" ? "unarchive" : "archive"}
                description={`Eski Unvan: ${terfi.eski_rutbe}`}
                title={`${terfi.yeni_rutbe} (${terfi.ilgili_bolum})`}
                dateTime={terfi.degisiklik_tarihi}
            />
        ));


        return (
            <Grid item xs={12} md={6} xl={6}>
                <Card className="h-100">
                    <SoftBox pt={3} px={3}>
                        <SoftTypography variant="h6" fontWeight="medium">
                            Amirler
                        </SoftTypography>
                        <SoftBox mt={1} mb={2}>
                            <SoftTypography variant="button" color="text" fontWeight="regular">
                                <SoftTypography display="inline" variant="body2" verticalAlign="middle">
                                    <Icon sx={{ fontWeight: "bold", color: "error"}}>
                                    label_important
                                    </Icon>
                                </SoftTypography>
                                &nbsp;
                                <SoftTypography variant="button" color="text" fontWeight="medium">
                                    { terfiRows1 > 0 ? terfiRows1.length-1 : "0"}
                                </SoftTypography>{" "}
                                Yetkili Üst Birim
                            </SoftTypography>
                        </SoftBox>
                    </SoftBox>
                    <SoftBox p={2}>
                        {terfiRows1}
                    </SoftBox>
                </Card>
                <Card className="h-100" style={{ marginTop: '30px'}}>
                    <SoftBox pt={3} px={3}>
                        <SoftTypography variant="h6" fontWeight="medium">
                            Terfi geçmişi
                        </SoftTypography>
                        <SoftBox mt={1} mb={2}>
                            <SoftTypography variant="button" color="text" fontWeight="regular">
                                <SoftTypography display="inline" variant="body2" verticalAlign="middle">
                                    <Icon sx={{ fontWeight: "bold", color: ({ palette: { success } }) => success.main }}>
                                        arrow_upward
                                    </Icon>
                                </SoftTypography>
                                &nbsp;
                                <SoftTypography variant="button" color="text" fontWeight="medium">
                                    {terfiRows.length}
                                </SoftTypography>{" "}
                                Terfi Güncellemesi
                            </SoftTypography>
                        </SoftBox>
                    </SoftBox>
                    <SoftBox p={2}>
                        {terfiRows}
                    </SoftBox>
                </Card>
            </Grid>
        );
    }
    catch (error) {
        console.log(error)
        return(
        <Grid item xs={12} md={12} xl={12}>
            <SoftBox pt={3} px={3}>
                <SoftTypography variant="h2" fontWeight="medium">
                    Yetkilendirilmemiş Giriş
                </SoftTypography>
            </SoftBox>
        </Grid>
        );
    }
}

export default index;
