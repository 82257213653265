/* eslint-disable react/prop-types */
import React from 'react'

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";


// @mui material components
import Icon from "@mui/material/Icon";
import { Avatar } from '@mui/material';

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftProgress from "components/SoftProgress";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import Table from "examples/Tables/Table";

import logoNewspaper from "assets/images/small-logos/application-4.png";

function Completion({ value, color }) {
  return (
    <SoftBox display="flex" alignItems="center">
      <SoftTypography variant="caption" color="text" fontWeight="medium">
        {value}%&nbsp;
      </SoftTypography>
      <SoftBox width="8rem">
        <SoftProgress value={value} color={color} variant="gradient" label={false} />
      </SoftBox>
    </SoftBox>
  );
}

const action = (
  <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small">
    more_vert
  </Icon>
);

const truncatedTextStyle = {
  width: '300px',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  height: '20px',
  WebkitBoxOrient: 'vertical'
};

const index = (data) => {
  const safeValue = (value) => value === null ? "-" : value;

  const egitimRows = data.data?.egitimler?.map(egitim => ({
    "Okul": [
      <img key="logoNewspaper" src={logoNewspaper} alt="Logo" />,
      <span key="task1" style={truncatedTextStyle}> {egitim.okul_adi} </span>
    ],
    "Fakülte": <SoftTypography variant="caption" color="text" fontWeight="medium">{egitim.fakulte}</SoftTypography>,
    "Bölüm": <SoftTypography variant="caption" color="text" fontWeight="medium">{egitim.bolum}</SoftTypography>,
    "Mezuniyet Tarihi": <SoftTypography variant="caption" color="text" fontWeight="medium">{egitim.mezuniyet_tarihi}</SoftTypography>,
    "Öğrenim Durumu": <SoftTypography variant="caption" color="text" fontWeight="medium">{egitim.ogrenim_durumu}</SoftTypography>,
    "Öğrenim Süresi": <SoftTypography variant="caption" color="text" fontWeight="medium">{egitim.ogrenim_suresi}</SoftTypography>,
    "Denklik Durumu": <SoftTypography variant="caption" color="text" fontWeight="medium">{egitim.denklik_durumu}</SoftTypography>,
  })) || [];
  

  const projectsTableData = {
    columns: [
      { name: "Okul", align: "left" },
      { name: "Fakülte", align: "left" },
      { name: "Bölüm", align: "left" },
      { name: "Mezuniyet Tarihi", align: "center" },
      { name: "Öğrenim Durumu", align: "center" },
      { name: "Öğrenim Süresi", align: "center" },
      { name: "Denklik Durumu", align: "center" },
    ],

    rows: [
      ...egitimRows,

    ],
  };





  const egitimdilRows = data.data?.egitimdiller?.map(egitim => ({
    "Dil": [
      <img key="logoNewspaper" src={logoNewspaper} alt="Logo" />,
      <span key="task1" style={truncatedTextStyle}> {egitim.dil} </span>
    ],
    "Seviye": <SoftTypography variant="caption" color="text" fontWeight="medium">{egitim.seviye}</SoftTypography>,
    "Sertifika/Sınav": <SoftTypography variant="caption" color="text" fontWeight="medium">{egitim.sinav_turu}</SoftTypography>,
    "Derece": <SoftTypography variant="caption" color="text" fontWeight="medium">{egitim.sertifika_puan}</SoftTypography>,
  })) || [];




  const dilTableData = {
    columns: [
      { name: "Dil", align: "left" },
      { name: "Seviye", align: "left" },
      { name: "Sertifika/Sınav", align: "left" },
      { name: "Derece", align: "center" },
    ],

    rows: [
      ...egitimdilRows,

    ],
  };



  const meslekegitimRows = data.data?.meslekegitimler?.map(egitim => ({
    "Eğitim": [
      <img key="logoNewspaper" src={logoNewspaper} alt="Logo" />,
      <span key="task1" style={truncatedTextStyle}> {egitim.alinan_egitim} </span>
    ],
    "Kurum": <SoftTypography variant="caption" color="text" fontWeight="medium">{egitim.kurum}</SoftTypography>,
    "Sertifika": <SoftTypography variant="caption" color="text" fontWeight="medium">{egitim.sertifika}</SoftTypography>,
    "Açıklama": <SoftTypography variant="caption" color="text" fontWeight="medium">{egitim.aciklama}</SoftTypography>,
  })) || [];




  const meslekTableData = {
    columns: [
      { name: "Eğitim", align: "left" },
      { name: "Kurum", align: "left" },
      { name: "Sertifika", align: "left" },
      { name: "Açıklama", align: "center" },
    ],

    rows: [
      ...meslekegitimRows,

    ],
  };

  return (
    <>

      <SoftBox display="flex" alignItems="center">
       
          Eğitim Bilgileri
        
      </SoftBox>


      <Grid item xs={12} md={12} xl={12}>
        <Table columns={projectsTableData.columns} rows={projectsTableData.rows} />
      </Grid>


      <SoftBox display="flex" alignItems="center">
       
          Meslek Eğitim Bilgileri
        
      </SoftBox>


      <Grid item xs={12} md={12} xl={12}>
        <Table columns={meslekTableData.columns} rows={meslekTableData.rows} />
      </Grid>


      <SoftBox display="flex" alignItems="center">
       
          Dil Eğitimi Bilgileri
        
      </SoftBox>


      <Grid item xs={12} md={12} xl={12}>
        <Table columns={dilTableData.columns} rows={dilTableData.rows} />
      </Grid>
    </>
  )
}

export default index
