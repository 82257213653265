/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import axios from "axios";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";
import Table from "examples/Tables/Table";

import team2 from "assets/images/team-takvim.jpg";

// const BASE_URL = process.env.REACT_APP_URL

const ayIsimleri = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];

function Author({ image, name, email, tel }) {
  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar src={image} alt={name} size="sm" variant="rounded" />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        {email && (
          <SoftTypography variant="caption" color="dark">
            {email}
          </SoftTypography>
        )}
        {tel && (
          <SoftTypography variant="caption" color="secondary">
            {tel}
          </SoftTypography>
        )}
      </SoftBox>
    </SoftBox>
  );
}

const index = ({ data }) => {
  const [dataNews, setDataNews] = useState({ veriler: [] });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/inews/${data?.data?.id}`, { withCredentials: true });
        setDataNews(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [data]);

  const aylikVeriler = [];

  dataNews.veriler.forEach((yilVerisi) => {
    const yil = yilVerisi.yil;
    Object.keys(yilVerisi?.yillik_haber_turleri_toplami || {}).forEach((ay) => {
      const ayVerisi = yilVerisi.yillik_haber_turleri_toplami[ay];
      aylikVeriler.push({
        tarih: `${yil}`,
        ay_toplam: yilVerisi.yil_toplam,
        haber_turleri: { [ay]: ayVerisi },
      });
    });
  });

  // Büyükten küçüğe sıralama
  aylikVeriler.sort((a, b) => {
    const tarihA = new Date(a.tarih);
    const tarihB = new Date(b.tarih);
    return tarihB - tarihA;
  });

  const yearlist = [];
  const rows = [];

  aylikVeriler.forEach((aylikVeri) => {
    const { tarih, ay_toplam, haber_turleri } = aylikVeri;

    const yil = tarih;
    if (!yearlist[tarih]) {
      yearlist[tarih] = true;
      rows.push({
        Adı: <Author image={team2} name={`${yil} Yılı Performans`} tel="" />,
        Kategori: <SoftBadge variant="gradient" badgeContent="TÜMÜ" color="dark" size="xs" container />,
        "Aylık Performans": (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {ay_toplam}
          </SoftTypography>
        ),
        Yıl: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {yil}
          </SoftTypography>
        ),
        field_kategori: "TÜMÜ",
      });
    }

    Object.keys(haber_turleri).forEach((item) => {
      rows.push({
        Adı: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium" px={3}>
            Yıllık Veriler
          </SoftTypography>
        ),
        Kategori: (
          <SoftBadge variant="gradient" badgeContent={item} color="info" size="xs" container />
        ),
        "Aylık Performans": (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {haber_turleri[item]}
          </SoftTypography>
        ),
        Yıl: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {yil}
          </SoftTypography>
        ),
        field_kategori: item,
        field_yil: yil,
      });
    });
  });

  const columns = [
    { name: "Adı", align: "left", exportableField: "field_name" },
    { name: "Kategori", align: "left", exportableField: "field_kategori", searchable: true, searchField: ["field_kategori"] },
    { name: "Aylık Performans", align: "center" },
    { name: "Yıl", align: "center", searchable: true, searchField: ["field_yil"] },
  ];

  return (
    <Grid item xs={12} md={12} xl={12}>
      <SoftBox pt={3} px={3}></SoftBox>
      <Card>
        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          <SoftTypography variant="h6">Yıllık Performans Tablosu</SoftTypography>
        </SoftBox>
        <SoftBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          <Table columns={columns} rows={rows} />
        </SoftBox>
      </Card>
    </Grid>
  );
};

export default index;
