// Soft UI Dashboard React layouts
import Dashboard from "layouts/dashboard";
import Orgchar from "layouts/orgchar";
import Calisanlar from "layouts/calisanlar";
import Tables from "layouts/tables";
import EskiPersonel from "layouts/eski-personel";
import Performans from "layouts/performans-list";
import Graphs from "layouts/graphs";
import Isealim from "layouts/ise-alim";
import StoryHunter from "layouts/storyhunter";
import Konuk from "layouts/konuk";
import PuanlamaList from "layouts/puanlama-list";
import PuanlamaForm from "layouts/puanlama-form";
import BankaIban from "layouts/banka-iban"
import Intibak from "layouts/intibak";
import IntibakCikis from "layouts/intibakcikis";
import BilgiNot from "layouts/bilgi-notlari";
import Disiplins from "layouts/disiplins";
import Freelance from "layouts/freelancer";
import FreelanceUsers from "layouts/freelance-users";
import Muhabir from "layouts/muhabir";
import Yayin from "layouts/yayin";
import Rota from "layouts/rota";
import Zimmet from "layouts/zimmet";
import Izinler from "layouts/izinler";
import Hedefler from "layouts/hedefler";
import KanalRapor from "layouts/kanal-rapor";

import GorevTalep from "layouts/planlama/gorev-talep"
import GorevTakip from "layouts/planlama/gorev-takip"
import GorevBilgi from "layouts/planlama/gorev-bilgi"
import GorevRapor from "layouts/planlama/gorev-rapor"
import PersonelRapor from "layouts/planlama/personel-rapor"
import GorevOrganizasyon from "layouts/planlama/gorev-organizasyon"

import Mahkeme from "layouts/hukuki-isler/mahkeme-karar"
import Sozlesmeler from "layouts/hukuki-isler/sozlesmeler"
import Gorusler from "layouts/hukuki-isler/hukuki-gorusler"

import Mevzuat from "layouts/mevzuatlar";
import Kisaltmalar from "layouts/kisaltmalar";
import Sozluk from "layouts/sozluk";
import Servis from "layouts/servis";
import Istanimi from "layouts/istanimi";
import Editoryel from "layouts/editoryel";

import Yapayzeka from "layouts/yapayzeka";
import Degerlendirme from "layouts/degerlendirme";

import TumYapımlar from "layouts/yapimlar/tum-yapimlar"
import IcYapımlar from "layouts/yapimlar/ic-yapimlar"
import Reversioning from "layouts/yapimlar/reversioning"
import Belgeseller from "layouts/yapimlar/belgeseller"
import YapimButce from "layouts/programlar-butce"

import Commissioning from "layouts/yapimlar/commissioning"
import Promolar from "layouts/yapimlar/promolar"
import Advertising from "layouts/yapimlar/advertising"

import ProgramOdeme from "layouts/yapimlar/program-odeme"

import DogumGun from "layouts/etkilesim/dogum-gunu"
import Memnuniyet from "layouts/etkilesim/memnuniyet-anket"


import Billing from "layouts/billing";
import VirtualReality from "layouts/virtual-reality";
import RTL from "layouts/rtl";
import Profile from "layouts/profile";
import Program from "layouts/program";
import HaberBulten from "layouts/haber-bulten";
import SignIn from "layouts/authentication/sign-in";
import TwoFactor from "layouts/authentication/two-factor";
import CV from "layouts/cv"
import Ayarlar from "layouts/ayarlar"
import Rapor from "layouts/rapor"
import Destek from "layouts/destek"
import DestekTalep from "layouts/destek/form"

import CreateForm from "layouts/form/create"
import EditForm from "layouts/form/edit"


// Soft UI Dashboard React icons
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import Settings from "examples/Icons/Settings";
import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";
import CreditCard from "examples/Icons/CreditCard";
import Cube from "examples/Icons/Cube";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBalanceScaleLeft, faBoxArchive, faCamera, faFileAlt, faFilm, faGift, faGifts, faHouse, faPenNib, faPhotoFilm, faPlaneDeparture, faSatelliteDish, faStar, faVideo } from '@fortawesome/free-solid-svg-icons'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons'
import { faHeadSideVirus } from '@fortawesome/free-solid-svg-icons'


import React, { useEffect, useState } from 'react';



let userPermissions = {

  //Kurum
  AccKurum: true,
  AccGraphs: true,
  AccOrgchar: true,

  //IK
  AccPersonelListe: true,
  AccIsealim: true,
  AccPerformans: true,
  AccDegerlendirme: true,
  AccHedefler: true,
  AccBilgiNot: true,
  AccEskiPersonel: true,
  AccIntibak: true,

  //MALI
  AccKonuk: true,
  AccFreelance: true,
  AccStoryHunter: true,

  //GOREVLENDIRME
  AccGorevTalep: true,
  AccGorevTakip: true,
  AccGorevOrganizasyon: true,
  AccGorevBilgi: true,
  AccGorevPersonel: true,
  AccGorevRapor: true,
  AccGorevPersonelRapor: true,

  //HUKUKI
  AccMahkeme: true,
  AccDisiplin: true,
  AccSozlesme: true,
  AccGorusler: true,

  //KOORDINASYON
  AccIzinler: true,
  AccRota: true,
  AccZimmet: true,
  

  //YAPIMLAR
  AccYapimlar: true,
  AccBulten: true,
  AccYapimButce: true,

  //PUANLAMA
  AccPuanlama: true,

  //STRATEJI
  AccMuhabir: true,
  AccYayin: true,
  AccKpi: true,
  AccEgitim: true,
  AccKanalRapor: true,

  //REHBERLER
  AccMevzuat: true,
  AccKisaltmalar: true,
  AccIstanimi: true,
  AccSozluk: true,
  AccEditoryel: true,
  AccServis: true,

  //YAPAY ZEKA
  AccYapayzeka: true,

};


const routes = [
  {
    type: "collapse",
    name: "Kurum",
    key: "kurum",
    route: "/kurum",
    icon: <FontAwesomeIcon icon={faHouse} size="xs" />,
    children: [
      {
        type: "collapse",
        name: "Anasayfa",
        key: "anasayfa",
        route: "/kurum/anasayfa",
        component: <Dashboard />,
        permissions: ['AccKurum'],
      },
      {
        type: "collapse",
        name: "Grafikler",
        key: "grafikler",
        route: "/kurum/grafikler",
        component: <Graphs />,
        permissions: ['AccGraphs'],
      },
      {
        type: "collapse",
        name: "Organizasyon Şeması",
        key: "organizasyon-sema",
        routeAlt: "Organizasyon Şeması",
        route: "/kurum/organizasyon-sema",
        component: <Orgchar />,
        permissions: ['AccOrgchar'],
      },
      // {
      //   type: "collapse",
      //   name: "Çalışanlar",
      //   key: "calisanlar",
      //   route: "/kurum/calisanlar",
      //   component: <Calisanlar />,
      //   permissions: ['AccOrgchar'],
      // },

    ]
  },



  {
    type: "collapse",
    name: "İnsan Kaynakları",
    key: "insan-kaynaklari",
    route: "/insan-kaynaklari",
    routeAlt: "İnsan Kaynakları",
    icon: <FontAwesomeIcon icon={faUsers} size="xs" />,
    noCollapse: true,
    children: [
      {
        type: "collapse",
        name: "Personel Listesi",
        key: "personel-listesi",
        route: "/insan-kaynaklari/personel-listesi",
        component: <Tables />,
        permissions: ['AccPersonelListe'],
      },
      {
        type: "collapse",
        name: "İşe Alım",
        key: "ise-alim",
        route: "/insan-kaynaklari/ise-alim",
        component: <Isealim />,
        permissions: ['AccIsealim'],
      },
      {
        type: "collapse",
        name: "Performans",
        key: "performans",
        route: "/insan-kaynaklari/performans",
        component: <Yapayzeka />,
        permissions: ['AccPerformans'],
      },
      {
        type: "collapse",
        name: "Değerlendirme",
        key: "degerlendirme",
        route: "/insan-kaynaklari/degerlendirme",
        component: <Degerlendirme />,
        permissions: ['AccDegerlendirme'],
      },
      {
        type: "collapse",
        name: "Hedefler",
        key: "hedefler",
        route: "/insan-kaynaklari/hedefler",
        component: <Hedefler />,
        permissions: ['AccHedefler'],
      },
      {
        type: "collapse",
        name: "Bilgi Notları",
        key: "bilgi-notlari",
        route: "/insan-kaynaklari/bilgi-notlari",
        component: <BilgiNot />,
        permissions: ['AccBilgiNot'],
      },
      {
        type: "collapse",
        name: "Eski Personeller",
        key: "eski-personel",
        route: "/insan-kaynaklari/eski-personel",
        component: <EskiPersonel />,
        permissions: ['AccEskiPersonel'],
      },
      {
        type: "collapse",
        name: "İşe Giriş",
        key: "intibak",
        route: "/insan-kaynaklari/intibak",
        component: <Intibak />,
        permissions: ['AccIntibak'],
      },
      {
        type: "collapse",
        name: "İşten Çıkış",
        key: "intibak-cikis",
        route: "/insan-kaynaklari/intibak-cikis",
        component: <IntibakCikis />,
        permissions: ['AccIntibak'],
      },

    ]
  },
  {
    type: "collapse",
    name: "Mali İşler",
    key: "mali-isler",
    route: "/mali-isler",
    icon: <FontAwesomeIcon icon={faMoneyBill} size="xs" />,
    noCollapse: true,
    children: [
      {
        type: "collapse",
        name: "Konuk Ödemeleri",
        key: "konuk-odemeleri",
        route: "/mali-isler/konuk-odemeleri",
        component: <Konuk />,
        permissions: ['AccKonuk'],
      },
      {
        type: "collapse",
        name: "Serbest Muhabirler",
        key: "freelance-muhabir",
        route: "/mali-isler/freelance-muhabir",
        component: <FreelanceUsers />,
        permissions: ['AccFreelance'],
      },
      {
        type: "collapse",
        name: "StoryHunter Personel",
        key: "storyhunter",
        route: "/mali-isler/storyhunter",
        component: <StoryHunter />,
        permissions: ['AccStoryHunter'],
      },
      {
        type: "collapse",
        name: "Program Ödemeleri",
        key: "program-odemeleri",
        route: "/mali-isler/program-odemeleri",
        component: <ProgramOdeme />,
        permissions: ['AccYapimButce'],
      },

    ]
  },


  {
    type: "collapse",
    name: "Görevlendirme",
    key: "gorevlendirme",
    route: "/gorevlendirme",
    icon: <FontAwesomeIcon icon={faPlaneDeparture} size="xs" />,
    noCollapse: true,
    children: [
      {
        type: "collapse",
        name: "Görev Talep",
        key: "gorev-talep",
        route: "/gorevlendirme/gorev-talep",
        component: <GorevTalep />,
        permissions: ['AccGorevTalep'],
      },

      {
        type: "collapse",
        name: "Görev Takip",
        key: "gorev-takip",
        route: "/gorevlendirme/gorev-takip",
        component: <GorevTakip />,
        permissions: ['AccGorevTakip'],
      },

      {
        type: "collapse",
        name: "Görev Organizasyon",
        key: "gorev-organizasyon",
        route: "/gorevlendirme/gorev-organizasyon/:id",
        component: <GorevOrganizasyon />,
        permissions: ['AccGorevOrganizasyon'],
      },
      {
        type: "collapse",
        name: "Görev Bilgi",
        key: "gorev-bilgi",
        route: "/gorevlendirme/gorev-bilgi/:id",
        component: <GorevBilgi />,
        permissions: ['AccGorevBilgi'],
      },

      {
        type: "collapse",
        name: "Personel Bilgileri",
        key: "personel-bilgileri",
        route: "/gorevlendirme/personel-bilgileri",
        component: <BankaIban />,
        permissions: ['AccGorevPersonel'],
      },

      {
        type: "collapse",
        name: "Görev Rapor",
        key: "gorev-rapor",
        route: "/gorevlendirme/gorev-rapor",
        component: <GorevRapor />,
        permissions: ['AccGorevRapor'],
      },

      {
        type: "collapse",
        name: "Personel Rapor",
        key: "personel-rapor",
        route: "/gorevlendirme/personel-rapor",
        component: <PersonelRapor />,
        permissions: ['AccGorevPersonelRapor'],
      },


    ]
  },


  {
    type: "collapse",
    name: "Hukuki İşler",
    key: "hukuki-isler",
    route: "/hukuki-isler",
    icon: <FontAwesomeIcon icon={faBalanceScaleLeft} size="xs" />,
    noCollapse: true,
    children: [
      {
        type: "collapse",
        name: "Disiplin Soruşturmaları",
        key: "disiplin",
        route: "/hukuki-isler/disiplin",
        component: <Disiplins />,
        permissions: ['AccDisiplin'],
      },
      {
        type: "collapse",
        name: "Hukuki Görüşler",
        key: "hukuki-gorusler",
        route: "/hukuki-isler/hukuki-gorusler",
        component: <Gorusler />,
        permissions: ['AccGorusler'],
      },
      {
        type: "collapse",
        name: "Sözleşmeler",
        key: "sozlesmeler",
        route: "/hukuki-isler/sozlesmeler",
        component: <Sozlesmeler />,
        permissions: ['AccSozlesme'],
      },
      {
        type: "collapse",
        name: "Yayın Yasağı kararları",
        key: "mahkeme-karalari",
        route: "/hukuki-isler/mahkeme-karalari",
        component: <Mahkeme />,
        permissions: ['AccMahkeme'],
      },



    ]
  },

  {
    type: "collapse",
    name: "Koordinasyon",
    key: "koordinasyon",
    route: "/koordinasyon",
    icon: <FontAwesomeIcon icon={faSatelliteDish} size="xs" />,
    noCollapse: true,
    children: [
      {
        type: "collapse",
        name: "İzinler",
        key: "izinler",
        route: "/koordinasyon/izinler",
        component: <Izinler />,
        permissions: ['AccIzinler'],
      },
      {
        type: "collapse",
        name: "Rota",
        key: "rota",
        route: "/koordinasyon/rota",
        component: <Rota />,
        permissions: ['AccRota'],
      },
      {
        type: "collapse",
        name: "Zimmet",
        key: "zimmet",
        route: "/koordinasyon/zimmet",
        component: <Zimmet />,
        permissions: ['AccZimmet'],
      },

    ]
  },

  {
    type: "collapse",
    name: "Yapımlar",
    key: "yapimlar",
    route: "/yapimlar",
    icon: <FontAwesomeIcon icon={faVideo} size="xs" />,
    noCollapse: true,
    children: [
      {
        type: "collapse",
        name: "Haber Bültenleri",
        key: "bultenler",
        route: "/yapimlar/bultenler",
        component: <HaberBulten />,
        permissions: ['AccBulten'],
      },
      {
        type: "collapse",
        name: "Tüm Yapımlar",
        key: "tum-yapimlar",
        route: "/yapimlar/tum-yapimlar",
        component: <TumYapımlar />,
        permissions: ['AccYapimlar'],
      },
      {
        type: "collapse",
        name: "İç Yapımlar",
        key: "ic-yapimlar",
        route: "/yapimlar/ic-yapimlar",
        component: <IcYapımlar />,
        permissions: ['AccYapimlar'],
      },
      {
        type: "collapse",
        name: "Reversioning",
        key: "reversioning",
        route: "/yapimlar/reversioning",
        component: <Reversioning />,
        permissions: ['AccYapimlar'],
      },

      {
        type: "collapse",
        name: "Commissioning",
        key: "commissioning",
        route: "/yapimlar/commissioning",
        component: <Commissioning />,
        permissions: ['AccYapimlar'],
      },

      {
        type: "collapse",
        name: "Belgeseller",
        key: "belgeseller",
        route: "/yapimlar/belgeseller",
        component: <Belgeseller />,
        permissions: ['AccYapimlar'],
      },
      {
        type: "collapse",
        name: "Promolar",
        key: "promolar",
        route: "/yapimlar/promolar",
        component: <Promolar />,
        permissions: ['AccYapimlar'],
      },
      {
        type: "collapse",
        name: "Advertising",
        key: "advertising",
        route: "/yapimlar/advertising",
        component: <Advertising />,
        permissions: ['AccYapimlar'],
      },

      {
        type: "collapse",
        name: "Yapımlar Bütçe",
        key: "yapim-butce",
        route: "/yapimlar/yapim-butce",
        component: <YapimButce />,
        permissions: ['AccYapimButce'],
      },

    ]
  },


  {
    type: "collapse",
    name: "Raporlama",
    key: "raporlama",
    route: "/raporlama",
    icon: <FontAwesomeIcon icon={faFileAlt} size="xs" />,
    children: [
      {
        type: "collapse",
        name: "Haber Üretimi",
        key: "haber-uretim",
        route: "/raporlama/haber-uretim",
        component: <Muhabir />,
        permissions: ['AccHaberUretim'],
      },
      // {
      //   type: "collapse",
      //   name: "*Muhabirler",
      //   key: "muhabir",
      //   route: "/raporlama/muhabir",
      //   component: <Yayin />,
      //   permissions: ['AccMuhabir'],
      // },
      {
        type: "collapse",
        name: "Yayın Faaliyetleri",
        key: "yayin-faaliyet",
        route: "/raporlama/yayin-faaliyet",
        component: <Yayin />,
        permissions: ['AccYayin'],
      },
      // {
      //   type: "collapse",
      //   name: "*KPI",
      //   key: "kpi",
      //   route: "/raporlama/kpi",
      //   component: <Tables />,
      //   permissions: ['AccKpi'],
      // },
      // {
      //   type: "collapse",
      //   name: "*Eğitim Çalışanı",
      //   key: "egitim-calisani",
      //   route: "/raporlama/egitim-calisani",
      //   component: <Tables />,
      //   permissions: ['AccEgitim'],
      // },
      {
        type: "collapse",
        name: "Kanal Rapor",
        key: "kanal-rapor",
        route: "/raporlama/kanal-rapor/:id",
        component: <KanalRapor />,
        permissions: ['AccKanalRapor'],
      },

    ]
  },


  {
    type: "collapse",
    name: "Rehberler",
    key: "rehberler",
    route: "/rehberler",
    icon: <FontAwesomeIcon icon={faPenNib} size="xs" />,
    noCollapse: true,
    children: [
      {
        type: "collapse",
        name: "Mevzuat",
        key: "mevzuat",
        route: "/rehberler/mevzuat",
        component: <Mevzuat />,
        permissions: ['AccMevzuat'],
      },
      {
        type: "collapse",
        name: "Kısaltmalar",
        key: "kisaltmalar",
        route: "/rehberler/kisaltmalar",
        component: <Kisaltmalar />,
        permissions: ['AccKisaltmalar'],
      },
      {
        type: "collapse",
        name: "İş Tanımı",
        key: "is-tanimi",
        route: "/rehberler/is-tanimi",
        component: <Istanimi />,
        permissions: ['AccIstanimi'],
      },
      {
        type: "collapse",
        name: "Sözlük",
        key: "sozluk",
        route: "/rehberler/sozluk",
        component: <Sozluk />,
        permissions: ['AccSozluk'],
      },
      {
        type: "collapse",
        name: "Editoryal Rehber",
        key: "rehber",
        route: "/rehberler/rehber",
        component: <Editoryel />,
        permissions: ['AccEditoryel'],
      },
      {
        type: "collapse",
        name: "Servis Saatleri",
        key: "servis-saatleri",
        route: "/rehberler/servis-saatleri",
        component: <Servis />,
        permissions: ['AccServis'],
      },

    ]
  },

  {
    type: "collapse",
    name: "Etkileşim",
    key: "etkilesim",
    route: "/etkilesim",
    icon: <FontAwesomeIcon icon={faGifts} size="xs" />,
    noCollapse: true,
    children: [
      {
        type: "collapse",
        name: "Doğum Günleri",
        key: "dogum-gunleri",
        route: "/etkilesim/dogum-gunleri",
        component: <DogumGun />,
        permissions: ['AccDogum'],
      },
      {
        type: "collapse",
        name: "Memnuniyet Anketi",
        key: "memnuniyet-anketi",
        route: "/etkilesim/memnuniyet-anketi",
        component: <Memnuniyet />,
        permissions: ['AccMemnuniyet'],
      },
    ]
  },

  {
    type: "collapse",
    name: "Puanlama",
    key: "puanlama",
    route: "/puanlama",
    icon: <FontAwesomeIcon icon={faStar} size="xs" />,
    noCollapse: true,
    children: [
      {
        type: "collapse",
        name: "Puanlama Liste",
        key: "puanlama-liste",
        route: "/puanlama/puanlama-liste",
        component: <PuanlamaList />,
        permissions: ['AccPuanlama'],
      },
    ]
  },

  {
    type: "collapse",
    name: "Yapay Zeka",
    key: "yapayzeka",
    route: "/yapayzeka",
    icon: <FontAwesomeIcon icon={faHeadSideVirus} size="xs" />,
    noCollapse: true,
    children: [
      {
        type: "collapse",
        name: "Veriler",
        key: "veriler",
        route: "/yapayzeka/veriler",
        component: <Yapayzeka />,
        permissions: ['AccYapayzeka'],
      },
    ]
  },

  // { type: "title", title: "Hesap Bilgileri", key: "account-pages" },
  {
    type: "title",
    name: "Profil",
    key: "profile",
    route: "/profile/:id",
    icon: <CustomerSupport size="12px" />,
    component: <Profile />,
    noCollapse: true,
  },
  {
    type: "title",
    name: "Destek",
    key: "destek",
    route: "/destek",
    icon: <CustomerSupport size="12px" />,
    component: <Destek />,
    noCollapse: true,
  },
  {
    type: "title",
    name: "Destek Talep",
    key: "talep-olustur",
    route: "/destek/talep-olustur",
    icon: <CustomerSupport size="12px" />,
    component: <DestekTalep />,
    noCollapse: true,
  },
  {
    type: "title",
    name: "Program",
    key: "program",
    route: "/program/:id",
    icon: <CustomerSupport size="12px" />,
    component: <Program />,
    noCollapse: true,
  },
  {
    type: "title",
    name: "Haber Bülten",
    key: "haber-bulten",
    route: "/haber-bulten/:id",
    icon: <CustomerSupport size="12px" />,
    component: <HaberBulten />,
    noCollapse: true,
  },
  {
    type: "title",
    name: "Giriş Yap",
    key: "sign-in",
    route: "/authentication/sign-in",
    icon: <Document size="12px" />,
    component: <SignIn />,
    noCollapse: true,
  },
  {
    type: "title",
    name: "2 Adımlı Doğrulama",
    key: "two-factor",
    route: "/authentication/two-factor",
    icon: <Document size="12px" />,
    component: <TwoFactor />,
    noCollapse: true,
  },
  {
    type: "title",
    name: "CV",
    key: "cv",
    route: "/cv/:id",
    icon: <Document size="12px" />,
    component: <CV />,
    noCollapse: true,
  },

  {
    type: "title",
    name: "Ayarlar",
    key: "ayarlar",
    route: "/ayarlar",
    icon: <Document size="12px" />,
    component: <Ayarlar />,
    noCollapse: true,
  },

  {
    type: "title",
    name: "Rapor",
    key: "rapor",
    route: "/rapor/:id/:view",
    icon: <Document size="12px" />,
    component: <Rapor />,
    noCollapse: true,
  },

  {
    type: "title",
    name: "puanlama-form",
    key: "puanlama-form",
    route: "/puanlama/puanlama-form/:id",
    icon: <Document size="12px" />,
    component: <PuanlamaForm />,
    noCollapse: true,
  },

  {
    type: "title",
    name: "form",
    key: "form",
    route: "/form/olustur/:model_name",
    icon: <Document size="12px" />,
    component: <CreateForm operation="create" />,
    noCollapse: true,
  },
  {
    type: "title",
    name: "form",
    key: "form",
    route: "/form/duzenle/:model_name/:id",
    icon: <Document size="12px" />,
    component: <CreateForm operation="edit" />,
    noCollapse: true,
  },
  {
    type: "title",
    name: "form",
    key: "form",
    route: "/form/sil/:model_name/:id",
    icon: <Document size="12px" />,
    component: <CreateForm operation="delete" />,
    noCollapse: true,
  },



];

const filterRoutesByPermissions = (routes, userPermissions) => {
  return routes
    .filter(route => {
      // Ana rota izin kontrolü
      if (route.permissions) {
        const hasPermission = route.permissions.some(permission => userPermissions[permission]);

        if (!hasPermission) {
          // Eğer ana rota izinli değilse, çocuk rotalar kontrol ediliyor
          if (route.children && route.children.length > 0) {
            const hasChildPermission = route.children.some(child => 
              child.permissions && child.permissions.some(permission => userPermissions[permission])
            );
            if (hasChildPermission) {
              return true; // Eğer çocuklardan biri izinliyse rotayı tut
            }
          }
          return false; // Ne ana rota ne de çocuk rotalar izinli değil, rotayı kaldır
        }
      }

      return true; // Ana rota izinli
    })
    .map(route => {
      if (route.children && route.children.length > 0) {
        // Çocuk rotalar da filtreleniyor
        const filteredChildren = filterRoutesByPermissions(route.children, userPermissions);

        // Eğer çocuklardan hiçbiri izinli değilse, ana route'u da kaldır
        if (filteredChildren.length === 0) {
          return null;
        }

        return { ...route, children: filteredChildren };
      }

      return route; // Eğer çocuk rota yoksa, rotayı olduğu gibi döndür
    })
    .filter(route => route !== null); // Null olan rotaları filtrele
};




const filteredRoutes = filterRoutesByPermissions(routes, userPermissions);

// console.log("AA")
 console.log(filteredRoutes)
// console.log(typeof(filteredRoutes))
// return filteredRoutes



// const result = TableX();
// console.log("XX")
// console.log(result)
// console.log(typeof(result))
export const getFilteredRoutes = (userPermissions) => {
  const filteredRoutes = filterRoutesByPermissions(routes, userPermissions);
  console.log(filteredRoutes);
  return filteredRoutes;
};

export const getAllRoutes = () => {
  return routes;
};

export default routes;



