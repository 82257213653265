/* eslint-disable react/prop-types */

import axios from "axios"

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "examples/Tables/Table";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PermissionNavbar from "examples/Navbars/PermissionNavbar";

import Header from "layouts/izinler/Header";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons'


function Tables() {






  const navigate = useNavigate();
  //const [data, setData] = useState([]);



  const truncatedTextStyle = {
    width: '250px',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    height: '20px',
    WebkitBoxOrient: 'vertical'
  };

  const [data, setData] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/izinler?format=json`, { withCredentials: true });
        setData(response.data);
      } catch (error) {
        console.log(error);

      }
    };

    fetchData();
  }, []);


  let egitimRows = data.map(index => {
    return {
      "Adı": [
        <img key="" src="12" alt="Logo" />,
        <span key="task1" style={truncatedTextStyle}> {index.user.name + " " + index.user.surname}  </span>
      ],
      "Gün": <SoftBadge variant="gradient" badgeContent={index.gun_sayisi + " GÜN"} color="info" size="xs" container />,

      "Tarih": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.tarih}</SoftTypography>,

      "İzin Türü": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.izin_turu} </SoftTypography>,

      "Düzenleyen": [
        <a key="" />,
        <span style={truncatedTextStyle} key="task2" > {index.duzenleyen_kisi ? index.duzenleyen_kisi?.name + " " + index.duzenleyen_kisi?.surname : ""}  </span>
      ],

      "Düzenlenme Tarihi": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.talep_tarihi}</SoftTypography>,

      "Talep Şekli": <SoftTypography variant="caption" color="text" fontWeight="medium">{index.talep_sekli}</SoftTypography>,

      "Onaylayan / Reddeden": [
        <a key="" />,
        <span style={truncatedTextStyle} key="task2" > {index.talep_edilen ? index.talep_edilen?.name + " " + index.talep_edilen?.surname : ""}  </span>
      ],

      "Düzenleme": <><SoftTypography component="a" href={`/form/duzenle/Izinler/${index.id}`} variant="caption" color="secondary" fontSize="medium" > <FontAwesomeIcon icon={faPenToSquare} /></SoftTypography>
        <SoftTypography component="a" href={`/form/sil/Izinler/${index.id}`} variant="gradient" color="text" fontSize="medium" style={{marginLeft:"10px"}} > <FontAwesomeIcon icon={faTrash} /></SoftTypography>
      </>,

      "field_username": index.user.name + " " + index.user.surname,
      "field_gun": index.gun_sayisi,
      "field_tarih": index.tarih,



    };
  });

  const projectsTableData = {
    columns: [
      { name: "Adı", align: "left", exportableField: "field_username", searchable: true, searchField: ["field_username"] },
      { name: "Tarih", align: "center", exportableField: "field_tarih", searchable: true, searchField: ["field_tarih"] },
      { name: "Gün", align: "center", exportableField: "field_gun", searchable: true, searchField: ["field_gun"] },

      { name: "İzin Türü", align: "center" },
      { name: "Düzenleyen", align: "center" },
      { name: "Düzenlenme Tarihi", align: "center" },
      { name: "Talep Şekli", align: "center" },
      { name: "Onaylayan / Reddeden", align: "center" },
      { name: "Düzenleme", align: "center" },
      //{ name: "Durum", align: "left" },

    ],

    rows: [
      ...egitimRows,

    ],
  };











  return (

    <Grid item xs={12} md={12} xl={12}>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center" flexDirection="row-reverse" p={3} >
        <SoftButton variant="outlined" color="secondary" onClick={() => navigate('/form/olustur/Izinler')}>
          yenİ İzİn&nbsp;&nbsp;
          <Icon>create</Icon>
        </SoftButton>
      </SoftBox>
      <Card>
        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          <SoftTypography variant="h6">Yıllık İzinler Listesi</SoftTypography>
        </SoftBox>
        <SoftBox
          sx={{
            "& .MuiTableRow-root:not(:last-child)": {
              "& td": {
                borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              },
            },
          }}
        >
          <Table columns={projectsTableData.columns} rows={projectsTableData.rows} />
        </SoftBox>
      </Card>

    </Grid>


  );

}

export default Tables;
