import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-select components
import Select from "react-select";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";

// Custom styles for SoftSelect
import styles from "components/SoftSelect/styles";

/* eslint-disable react/prop-types */

const SoftSelect = forwardRef(({ size, error, success, noOptionsMessage, placeholder,disabled, ...rest }, ref) => {
  const { light } = colors;

  const toLocaleLowerCase = (str) => {
    return typeof str === "string" ? str.toLocaleLowerCase('tr-TR') : "";  // String değilse boş döndür
  };

  const toLocaleUpperCase = (str) => {
    return typeof str === "string" ? str.toLocaleUpperCase('tr-TR') : "";  // String değilse boş döndür
  };

  // Custom filter function to handle Turkish character search
  const customFilterOption = (option, rawInput) => {
    const inputValue = toLocaleLowerCase(rawInput);
    
    // Eğer 'search' alanı varsa, o alan ile arama yap
    const optionLabel = option.data.search 
      ? toLocaleLowerCase(option.data.search) 
      : toLocaleLowerCase(option.label.props?.name);  // Eğer search yoksa label'in name değerini kullan
    
    return optionLabel.includes(inputValue);
  };

  return (
    <Select
      {...rest}
      ref={ref}
      styles={styles(size, error, success)}
      filterOption={customFilterOption}
      noOptionsMessage={noOptionsMessage}
      placeholder={placeholder}
      isDisabled={disabled ? disabled : false}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: light.main,
          primary: light.main,
        },
      })}
    />
  );
});

// Setting default values for the props of SoftSelect
SoftSelect.defaultProps = {
  size: "medium",
  error: false,
  success: false,
  noOptionsMessage: () => "Seçenek yok",
  placeholder: "Seçiniz...",
};

// Typechecking props for the SoftSelect
SoftSelect.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large", "xlarge"]),
  error: PropTypes.bool,
  success: PropTypes.bool,
  noOptionsMessage: PropTypes.func,
  placeholder: PropTypes.string,
};

export default SoftSelect;
