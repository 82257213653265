/* eslint-disable react/prop-types */

import axios from "axios"

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "examples/Tables/Table";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PermissionNavbarPage from "examples/Navbars/PermissionNavbarPage";

import Header from "layouts/izinler/Header";

import IzinlerYillik from "layouts/izinler/Category/Yillik"
import IzinlerRapor from "layouts/izinler/Category/Rapor"
import IzinlerPersonel from "layouts/izinler/Category/Personel"


function Tables() {

  const { id } = useParams();





  try {
    const navigate = useNavigate();
    const [tabIndex, setTabIndex] = useState(0);

    const onTabChange = (index) => {
      setTabIndex(index);
    }

    const [data, setData] = useState([]);

    return (
      <DashboardLayout>
        < DashboardNavbar />
        <Header onChange={e => onTabChange(e)} data={data} />
        <SoftBox mt={5} mb={3}>
          <Grid container spacing={3} style={{justifyContent:"center"}}>

            {tabIndex === 0 && (
              <>
                <IzinlerYillik />


              </>
            )}
            {tabIndex === 1 && (
              <>
                <IzinlerRapor />

              </>
            )}
            {tabIndex === 2 && (
              <>
                <IzinlerPersonel />

              </>
            )}

          </Grid>
        </SoftBox>

      </DashboardLayout>
    );
  }
  catch (error) {
    console.log(error);
    return (
      <PermissionNavbarPage />

    );
  }
}

export default Tables;
