import React from 'react'

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";

const index = (data) => {
  console.log(data);
  const safeValue = (value) => value === null ? "-" : value;

  return (
    <>
      <Grid item xs={12} md={6} xl={4}>
        <ProfileInfoCard
          title="Kimlik Bilgileri"
          description="Kimlik bilgileri detayları"
          info={{
            "İsim": safeValue(data.data.name) + " " + safeValue(data.data.surname),     
            "Uyruk": safeValue(data.data.uyruk),
            "Cinsiyet": safeValue(data.data.cinsiyet),




          }}
          social={[
          ]}
          action={{ route: "/profile/edit", tooltip: "Profili Düzenle" }}
        />
      </Grid>
      <Grid item xs={12} md={6} xl={4}>
        <ProfileInfoCard
          title="Kurum Bilgileri"
          description="Kurum bilgileri detayları"
          info={{
            "Sicil No": safeValue(data.data.sicil_no),
            "İş Numarası": safeValue(data.data.is_cep_no),
            "TRT Email": safeValue(data.data.trt_mail),
            "TRT Arabi Email": safeValue(data.data.trt_arabi_mail),
            "Konumu": safeValue(data.data.calisma_yeri),
            "Mudurluk": safeValue(data.data.mudurluk)




          }}
          social={[
          ]}
          action={{ route: "/profile/edit", tooltip: "Profili Düzenle" }}
        />
      </Grid>
      <Grid item xs={12} md={6} xl={4}>
        <ProfileInfoCard
          title="İletişim Bilgileri"
          description="İletişim bilgileri detayları"
          info={{
            "Telefon Numarası": safeValue(data.data.kisisel_cep_no),
            "Email": safeValue(data.data.kisisel_mail),





          }}
          social={[
          ]}
          action={{ route: "/profile/edit", tooltip: "Profili Düzenle" }}
        />
      </Grid>
    </>
  )
}

export default index
