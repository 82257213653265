/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";


// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
//import authorsTableData from "layouts/tables/data/authorsTableData";
//import projectsTableData from "layouts/tables/data/projectsTableData";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faPenToSquare } from '@fortawesome/free-solid-svg-icons'

function Author({ id, image, name, email, tel }) {
  return (
    <a href={`/profile/${id}`}>
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
          <SoftAvatar src={image} size="sm" variant="rounded" />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column">
          <SoftTypography variant="button" fontWeight="medium">
            {name}
          </SoftTypography>
          <SoftTypography variant="caption" color="dark">
            {email}
          </SoftTypography>
          <SoftTypography variant="caption" color="secondary">
            {tel}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </a>
  );
}

function Function({ job, org }) {
  return (
    <SoftBox display="flex" flexDirection="column">
      <SoftTypography variant="caption" fontWeight="medium" color="text">
        {job}
      </SoftTypography>
      <SoftTypography variant="caption" color="secondary">
        {org}
      </SoftTypography>
    </SoftBox>
  );
}


function Tables() {


  
  const [authorsData, setAuthorsData] = useState({ columns: [], rows: [] });

  //const { columns: prCols, rows: prRows } = projectsTableData;

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      //console.log("VERİ")
      try {
        const response = await axios.get(`${window.BASE_URL}/pastuserlist?format=json`, { withCredentials: true });
        setData(response.data);
      } catch (error) {
        console.log(error);

      }
    };

    fetchData();
  }, []);




  const rows = data.map(item => ({
    Adı: <Author id={item.id} image={window.BASE_URL + item.personel_fotograf} name={item.name + " " + item.surname } email={item.trt_arabi_mail} tel={item.kisisel_cep_no} />,
    Pozisyon: <Function job={item.trt_arabi_unvan} org={item.trt_unvan} />,
    "Çıkış Tarihi": <SoftBadge variant="gradient" badgeContent={item.isten_cikis} color="default" size="xs" container />,
    Uyruk: <SoftTypography variant="caption" color="secondary" fontWeight="medium"> {item.uyruk} </SoftTypography>,
    "Çıkış Sebebi": <SoftTypography variant="caption" color="secondary" fontWeight="medium"> {item.ayrilma_sebebi} </SoftTypography>,
    Sicil: <SoftTypography variant="caption" color="secondary" fontWeight="medium"> {item.sicil_no} </SoftTypography>,
    Düzenleme: (<SoftTypography component="a" href={`profile/${item.id}`} variant="caption" color="secondary" fontWeight="medium" > <FontAwesomeIcon icon={faPenToSquare} /></SoftTypography>),
    "Kanal": <SoftBadge variant="gradient" badgeContent={item.kanal.kanal_adi} color="dark" size="xs"> </SoftBadge>,

    "field_kanal": item.kanal.kanal_adi,
    field_name: String(item.name + " " + item.surname),
    field_position: String(item.trt_arabi_unvan),
    field_status: String(item.isten_cikis),


  }));


  // columns ve rows listelerini içeren bir obje döndür
  const result = {
    columns: [
      { name: "Adı", align: "left", exportableField: "field_name", searchable: true, searchField: ["field_name"] },
      { name: "Çıkış Tarihi", align: "center", exportableField: "field_status" },
      { name: "Çıkış Sebebi", align: "center" },
      { name: "Sicil", align: "center" },
      { name: "Uyruk", align: "center" },
      { name: "Pozisyon", align: "left", exportableField: "field_position", searchable: true, searchField: ["field_position"] },
      

      //{ name: "Düzenleme", align: "center" },
    ],
    rows: rows,
  };


  const userKanal = useSelector(state => state.auth.userKanal);
  if (userKanal == "Global") {
    result.columns.splice(1, 0, { name: "Kanal", align: "center", exportableField: `field_kanal`, searchable: true, searchField: [`field_kanal`],});
  }


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Çalışan Listesi</SoftTypography>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={result.columns} rows={result.rows} edit="/admin/base/user/" />
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
