


import axios from "axios"

import React, { useEffect, useState } from 'react';

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import HeaderTitle from "layouts/headers/NormalHeader"
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";

// Dashboard layout components
import wavesWhite from "assets/images/shapes/waves-white.svg";
import rocketWhite from "assets/images/illustrations/network-trt.png";
import curved0 from "assets/images/curved-images/curved-empty.png"

import { Divider } from "@mui/material";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import gradientLineChartData from "layouts/dashboard/data/gradientLineChartData";

function Dashboard() {
    const { size } = typography;
    let { chart, items } = reportsBarChartData;


    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${window.BASE_URL}/birtday/count`, {
                    withCredentials: true
                });
                setData(response.data);
            } catch (error) {
                console.log(error);

            }
        };

        fetchData();
    }, []);

    let dataHeader1 = {
        "title": "Personel Doğum Günleri",
        "line1": "Çalışan Personel Doğum Bilgileri",
        "image": curved0,
        "color": "info"
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>

                <SoftBox mb={3}>
                    <Grid container spacing={3} justifyContent={"center"}>
                        <Grid item xs={12} lg={10}>
                            <HeaderTitle data={dataHeader1} />
                            <Divider />
                            <Grid container spacing={3} justifyContent={"center"}>
                                <Grid item xs={12} sm={4} xl={3}>
                                    <MiniStatisticsCard
                                        title={{ text: "Dün Doğanlar" }}
                                        count={data.yesterday_count}
                                        percentage={{ color: "light", text: "" }}
                                        icon={{ color: "light", component: "peopleAltIcon" }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} xl={3}>
                                    <MiniStatisticsCard
                                        title={{ text: "Bugün Doğanlar" }}
                                        count={data.today_count}
                                        percentage={{ color: "light", text: "" }}
                                        icon={{ color: "info", component: "cake" }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} xl={3}>
                                    <MiniStatisticsCard
                                        title={{ text: "Yarın Doğanlar" }}
                                        count={data.tomorrow_count}
                                        percentage={{ color: "light", text: "" }}
                                        icon={{ color: "light", component: "people" }}
                                    />
                                </Grid>
     
                            </Grid>
                        </Grid>
                        {data?.birthday_list?.map(item => (
    
                        <Grid item xs={12} lg={8.5} key="">
                            <Card>
                                <SoftBox p={2}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} lg={6}>
                                            <SoftBox display="flex" flexDirection="column" height="100%">
                                                <SoftBox pt={1} mb={0.5}>
                                                    <SoftTypography variant="body2" color="text" fontWeight="medium">
                                                        Doğum Günü 🎉
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftTypography variant="h5" fontWeight="bold" gutterBottom>
                                                    {item.name + " " + item.surname}
                                                </SoftTypography>
                                                <SoftBox mb={6}>
                                                    <SoftTypography variant="body2" color="text">
                                                        Doğum gününüzü kutluyor, şirketimize kattığınız değer ve özverili çalışmalarınız için teşekkür ediyoruz. Yeni yaşınızın sağlık, mutluluk ve başarı getirmesini dileriz ✨
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftTypography
                                                    component="a"
                                                    href="#"
                                                    variant="button"
                                                    color="text"
                                                    fontWeight="medium"
                                                    sx={{
                                                        mt: "auto",
                                                        mr: "auto",
                                                        display: "inline-flex",
                                                        alignItems: "center",
                                                        cursor: "pointer",

                                                        "& .material-icons-round": {
                                                            fontSize: "1.125rem",
                                                            transform: `translate(2px, -0.5px)`,
                                                            transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                                                        },

                                                        "&:hover .material-icons-round, &:focus  .material-icons-round": {
                                                            transform: `translate(6px, -0.5px)`,
                                                        },
                                                    }}
                                                >
                                                    Doğum Günü
                                                    <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                                                </SoftTypography>
                                            </SoftBox>
                                        </Grid>
                                        <Grid item xs={12} lg={5} sx={{ position: "relative", ml: "auto" }}>
                                            <SoftBox
                                                height="100%"
                                                display="grid"
                                                justifyContent="center"
                                                alignItems="center"
                                                bgColor="info"
                                                borderRadius="lg"
                                                variant="gradient"
                                            >
                                                <SoftBox
                                                    component="img"
                                                    src={wavesWhite}
                                                    alt="waves"
                                                    display="block"
                                                    position="absolute"
                                                    left={0}
                                                    width="100%"
                                                    height="100%"
                                                />
                                                <SoftBox component="img" src={rocketWhite} alt="rocket" width="100%" pt={3} />
                                            </SoftBox>
                                        </Grid>
                                    </Grid>
                                </SoftBox>
                            </Card>

                        </Grid>
                        ))}
                    </Grid>
                </SoftBox>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Dashboard;
