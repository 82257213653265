/* eslint-disable react/prop-types */

import axios from "axios"
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "examples/Tables/Table";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";
import HeaderTitle from "layouts/headers/NormalHeader"
//import curved0 from "assets/images/studio-5.jpg";
import curved0 from "assets/images/curved-images/curved-empty.png"

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";
import SoftAvatar from "components/SoftAvatar";
import SoftSelect from "components/SoftSelect";

import MiniInfoCard from "examples/Cards/InfoCards/MiniInfoCard";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import TimelineItem from "examples/Timeline/TimelineItem";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PermissionNavbar from "examples/Navbars/PermissionNavbar";

import Header from "layouts/izinler/Header";
import boxShadow from "assets/theme/functions/boxShadow";
import { Divider } from "@mui/material";


function Tables() {

  let dataHeader1 = {
    "title": "Kalan İzin Hesaplama",
    "line1": "Personel İzin Hesap Görünümü",
    "image": curved0,
    "color": "info"
  }
  let dataHeader2 = {
    "title": "Telafi İzin Bilgileri",
    "line1": "Personel Telafi Geçmişi",
    "image": curved0,
    "color": "info"
  }
  let dataHeader3 = {
    "title": "Personel İzin Geçmişi",
    "line1": "Genel İzinler ve Sağlık Raporları",
    "image": curved0,
    "color": "info"
  }
  

  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [userID, setUserID] = useState("0");
  const [usersList, setUsersList] = useState([]);

  useEffect(() => {
    // Başlangıçta kullanıcı listesini çek
    const fetchUserList = async () => {
      try {
        const response = await axios.get(`${window.BASE_URL}/izinler/${userID}?format=json`, { withCredentials: true });
        setUsersList(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUserList();
  }, []);

  useEffect(() => {
    // User ID değiştiğinde yeni verileri çek
    const fetchData = async () => {
      if (userID !== "0") {
        try {
          const response = await axios.get(`${window.BASE_URL}/izinler/${userID}?format=json`, { withCredentials: true });
          setData(response.data);
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchData();
  }, [userID]);

  function createItems(title, text) {
    let items = [];
    if (title) {
      items.push(
        <SoftBox pt={3} px={3} style={{ width: "300px" }}>
          <SoftTypography display="inline" variant="body2" verticalAlign="middle" fontSize="14px">
            <Icon sx={{ fontWeight: "bold", color: "error" }}>
              label_important
            </Icon>
            <strong>{title} </strong> <br />
            <SoftTypography variant="caption" color="secondary" fontWeight="medium" fontSize="12px"> {text}</SoftTypography>
          </SoftTypography>
        </SoftBox>
      );
    }
    return items;
  }

  const NewTarget = ({ name, image, color = "secondary", icon = "person_add", email, tel, style }) => (
    <SoftBadge style={style} variant="variant" badgeContent={
      <SoftBox display="flex" alignItems="center" px={1} py={0.5} style={{ width: "auto" }}>
        <SoftBox mr={2}>
          <SoftAvatar src={image} size="md" icon={icon} bgColor={color} variant="rounded" iconSize="24px !important" />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column" alignItems="flex-start">
          <SoftTypography display="inline" variant="body2" verticalAlign="middle" fontSize="14px" fontWeight="medium">{name}</SoftTypography>
          <SoftTypography variant="caption" color="text" fontWeight="medium">
            {email}
          </SoftTypography>
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {tel}
          </SoftTypography>
        </SoftBox>

      </SoftBox>
    } color="light" size="xl" container />
  );

  const TotalTarget = ({ name, image, color = "secondary", icon = "person_add", email, tel, style }) => (
    <SoftBadge style={{ "width": "100%", width: "-webkit-fill-available" }} variant="gradient" badgeContent={
      <SoftBox display="flex" alignItems="center" px={1} py={0.5} style={{ width: "-webkit-fill-available" }}>
        <SoftBox mr={2} >
          <SoftAvatar src={image} size="md" icon={icon} bgColor={color} variant="rounded" iconSize="24px !important" />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column" alignItems="flex-start" >
          <SoftTypography display="inline" variant="body2" verticalAlign="middle" fontSize="14px" fontWeight="medium">{name}</SoftTypography>
          <SoftTypography variant="caption" color="text" fontWeight="medium">
            {email}
          </SoftTypography>
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {tel}
          </SoftTypography>
        </SoftBox>

      </SoftBox>
    } color="light" size="xl" container />
  );

  const handleSelectChange = (selectedOption) => {
    setUserID(selectedOption.value);  // Seçilen kullanıcı ID'sini güncelle
  };

  const userOptions = Array.isArray(usersList) ? usersList.map(user => ({
    value: user.id,
    label: <NewTarget name={`${user.name} ${user.surname}`} image={window.BASE_URL + "/" + user.thumbnail} />,
    search: user.name + " " + user.surname
  })) : [];



  const transformIzinData = (izinAralik) => {
    const chart = {
      labels: Object.keys(izinAralik),
      datasets: {
        label: "Kullanılan Günler",
        data: Object.values(izinAralik).map(aralik => aralik.izinler.reduce((total, izin) => total + parseFloat(izin.kullanilan_gun), 0)),
      },
    };

    const items = Object.keys(izinAralik).map((key, index) => {
      const aralik = izinAralik[key];
      return {
        icon: { color: "info", component: "calendar_today" },
        label: `İzin Aralığı: ${key}`,
        progress: { content: `${aralik.gun_sayi}// Gün` },
      };
    });

    return { chart, items };
  };

  // data?.user_info?.izin_aralik verisini dönüştürme
  const izinData = data?.user_info?.izin_aralik;
  const transformedData = izinData ? transformIzinData(izinData) : { chart: [], items: [] };

  const Target = ({ id, image, name, email, tel, variant, index, color = "light" }) => {
    return (
      <a >
        <SoftBadge variant={variant} badgeContent={
          <SoftBox display="flex" alignItems="center" px={1} py={0.5} style={{ color: "white !important" }} >
            <SoftBox mr={2}>
              <Icon sx={{ fontWeight: "bold", fontSize: "16px !important" }}>
                payments
              </Icon>
            </SoftBox>
            <SoftBox display="flex" flexDirection="column" alignItems="flex-start">
              <SoftTypography variant="button" fontWeight="medium">
                {name}
              </SoftTypography>
              <SoftTypography variant="caption" color="dark">
                {email}
              </SoftTypography>
              <SoftTypography variant="caption" color="secondary">
                {tel}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        } color={color} size="xl" container fullWidth />
      </a>
    );
  };

  const Total = ({ id, image, name, email, tel, variant, index, color = "light" }) => {
    return (
      <a >
        <SoftBadge variant={variant} badgeContent={
          <SoftBox display="flex" alignItems="center" px={1} py={0.5} style={{ color: color== "light" ? "#354868 !important" : "white !important" }} >
            <SoftBox mr={2}>
              <Icon sx={{ fontWeight: "bold", fontSize: "16px !important", color: color== "light" ? "#354868 !important" : "white !important" }}>
                today
              </Icon>
            </SoftBox>
            <SoftBox display="flex" flexDirection="column" alignItems="flex-start">
              <SoftTypography sx={{ color: color== "light" ? "#354868 !important" : "white !important" }} variant="button" fontWeight="medium">
                {name}
              </SoftTypography>
              <SoftTypography variant="caption" color="dark">
                {email}
              </SoftTypography>
              <SoftTypography variant="caption" color="secondary">
                {tel}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        } color={color} size="sm" container fullWitdh />
      </a>
    );
  };


  let izinRows = data?.izinler?.map(item => (
    <TimelineItem
      key={item.izin_tarih}
      color="info"
      icon="date_range"
      description={`Gün Sayısı: ${item.gun_sayisi}`}
      title={(item.username)}
      dateTime={`İzin Tarihi: ${item.izin_tarih}`}
    />
  ));

  let raporizinRows = data?.rapor_izinler?.map(item => (
    <TimelineItem
      key={item.izin_tarih}
      color="info"
      icon="accessible"
      description={`Gün Sayısı: ${item.gun_sayisi}`}
      title={(item.username)}
      dateTime={`İzin Tarihi: ${item.izin_tarih}`}
    />
  ));


  return (
    <>

      <Grid item xs={12} md={8.5} xl={8.5} style={{ marginTop: "-24px" }}>
        <SoftBox p={3} style={{ boxShadow: "inset 0rem 0rem 0.0625rem 0.0625rem rgba(255, 255, 255, 0.9),0rem 1.25rem 1.6875rem 0rem rgba(0, 0, 0, 0.05)", borderRadius: "20px" }}>
          <SoftSelect
            defaultValue={[
              { value: "create_new_flavor", label: <NewTarget name="Personel Seçiniz..." image="" /> }
            ]}
            options={userOptions}
            error="true"
            size="xlarge"
            placeholder="Kişi ekle..."
            onChange={handleSelectChange}
          />
        </SoftBox>
      </Grid>
      <Grid item xs={12} sm={12} lg={12} style={{ marginTop: "-24px" }} />

      <Grid item xs={12} sm={6} lg={3}>
        <MiniInfoCard
          icon="beenhere"
          title={<span>Kullanılan İzinler</span>}
          count={data?.user_info ? data?.user_info?.toplam_kullanılan || 0 : ""}
          description={data?.user_info ? "Gün" : "Personel Seçin"}
          color={data?.user_info?.toplam_kullanılan > 0 ? "info" : "light"}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <MiniInfoCard
          icon="work_history"
          title={<span>Kıdem</span>}
          count={data?.user_info ? data?.user_info?.kidem || 0 : ""}
          description={data?.user_info ? "Yıl" : "Personel Seçin"}
          color={data?.user_info?.kidem > 0 ? "info" : "light"}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <MiniInfoCard
          icon="account_balance_wallet"
          title={<span>Kalan İzin</span>}
          count={data?.user_info ? data?.user_info?.toplam_kalan || 0 : ""}
          description={data?.user_info ? "Gün" : "Personel Seçin"}
          color={data?.user_info?.toplam_kalan > 0 ? "info" : "light"}
        />
      </Grid>

      <Grid item xs={12} sm={12} lg={12} />




      <Grid item xs={12} md={9} xl={9} style={{ marginTop: "-24px" }}>
        <SoftBox style={{ marginBottom: "24px" }}>
          <ReportsBarChart
            title="Yıllık İzin Kullanım Dağılımı"
            chart={transformedData.chart}
            items={transformedData.items}
          />
        </SoftBox>
        <HeaderTitle data={dataHeader1} />
        <br/>
      
        <Card>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <SoftTypography variant="h6">Personel İzin Hesaplama</SoftTypography>
          </SoftBox>



          <SoftBox my={-3} px={3} pb={6}>
            <Divider />
            <SoftBox style={{ display: "flex", flexWrap: "wrap" }}>
              {createItems("Sözleşme Başlangıç", data?.user_info?.sozlesme_baslangic)}
              {data?.user_info?.ise_baslangic ? <NewTarget name="Sözleşme Geçiş" color="light" icon="article" email={"➔ " + data?.user_info?.sozlesme_turu} tel={data?.user_info?.ise_baslangic} style={{ paddingTop: "22px" }}></NewTarget> : ""}
            </SoftBox>
            {createItems("Sözleşme Türü", data?.user_info?.sozlesme_turu)}
            {data?.user_info?.kazanc_sozlesme > 0 ? <SoftBadge style={{ marginLeft: "24px" }} variant="gradient" badgeContent={"+" + data?.user_info?.kazanc_sozlesme + " Gün"} color="info" size="xl" container /> : ""}

            <SoftBox style={{ display: "flex" }}>
              {createItems("Uyruk", data?.user_info?.uyruk)}
              {createItems("Vatandaşlık", data?.user_info?.vatandaslik)}
            </SoftBox>

            {createItems("Sendika Üyeliği", data?.user_info?.sendika ? data?.user_info?.sendika : "Yok")}
            {data?.user_info?.kazanc_sendika > 0 ? <SoftBadge style={{ marginLeft: "24px" }} variant="gradient" badgeContent={"+" + data?.user_info?.kazanc_sendika + " Gün"} color="info" size="xl" container /> : ""}

            {createItems("Yıllık Ekstra", "")}
            {data?.user_info?.kazanc_yillik > 0 ? <>
              <SoftBadge style={{ marginLeft: "24px" }} variant="gradient" badgeContent={"+" + data?.user_info?.kazanc_yillik + " Gün"} color="info" size="xl" container />
              {Object.entries(data?.user_info?.kazanc_yillik_detay).map(([year, value]) => (
                <>
                  <br />
                  <SoftTypography style={{ marginLeft: "24px" }} variant="caption" color="secondary" fontWeight="medium" fontSize="12px"> {year + ": +" + (0 == 0 ? "4" : value) + " Gün"}  </SoftTypography>
                </>
              ))}
            </>
              : ""}
            <br />
            <Divider />
            <TotalTarget name="Kalan İzin Detaylı Hesaplaması" color="dark" icon="article" email={"➔ " + "Sözleşme Türü + Yıllık Ekstra + Sendika Üyeliği - Kullanılan İzin"} tel={data?.user_info?.kazanc_sozlesme + " + " + data?.user_info?.kazanc_yillik + " + " + data?.user_info?.kazanc_sendika + " - " + data?.user_info?.toplam_kullanılan + " = " + data?.user_info?.toplam_kalan} style={{ paddingTop: "22px" }} />



          </SoftBox>

        </Card>

      </Grid>

      <Grid item xs={12} md={9.1} xl={9.2} >
        <HeaderTitle data={dataHeader2} />
      </Grid>


      <Grid item xs={12} md={8.9} xl={8.9} >

        <Card>

          <SoftBox pt={3} px={3}>
            <SoftTypography variant="h6" fontWeight="medium">
              Telafi Bakiye Durumu
            </SoftTypography>
            <SoftBox mt={1} mb={2}>
              <SoftTypography variant="button" color="text" fontWeight="regular">
                <SoftTypography display="inline" variant="body2" verticalAlign="middle">
                  <Icon sx={{ fontWeight: "bold", color: "error" }}>
                    label_important
                  </Icon>
                </SoftTypography>
                &nbsp;
                <SoftTypography variant="button" color="text" fontWeight="medium">

                </SoftTypography>{" "}
                Kalan Telafi İzin Hesaplaması
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <SoftBox p={2}>
            <Total name={"TOPLAM TELAFİ " + data?.user_info?.toplam_kullanılan + " GÜN"} color="info" />

            <br />
            <br />
            <div style={{ display: "flex", gap: "40px", flexWrap: "wrap" }}>
              <TimelineItem
                key={"item.izin_tarih"}
                color="secondary"
                icon="date_range"
                description={<>Tarih: 12-10-2000 (+1 Gün) <br /> Tarih: 30-09-2004 (+2 Gün)<br />Tarih: 02-11-2010 (+4 Gün)</>}
                title={("Kazanılan Telafiler")}
                dateTime={`Toplam Gün: ${"item.izin_tarih"}`}
              />
              <TimelineItem
                key={"item.izin_tarih"}
                color="secondary"
                icon="date_range"
                description={<>Tarih: 12-10-2000 (+1 Gün) <br /> Tarih: 30-09-2004 (+2 Gün)<br />Tarih: 02-11-2010 (+4 Gün)<br /> Tarih: 30-09-2004 (+2 Gün)<br />Tarih: 02-11-2010 (+4 Gün)</>}
                title={("Kullanılan Telafiler")}
                dateTime={`Toplam Gün: ${"item.izin_tarih"}`}
              />
            </div>
            <br />
            <TotalTarget name="Kalan Telafi İzin Detaylı Hesaplaması" color="info" icon="article" email={"➔ " + "Kazanılan Telafi - Kullanılan Telafi"} tel={data?.user_info?.kazanc_sozlesme + " - " + data?.user_info?.kazanc_yillik + " = " + data?.user_info?.toplam_kalan} style={{ paddingTop: "52px", }} />

          </SoftBox>
        </Card>

      </Grid>

      <Grid item xs={12} md={9.1} xl={9.2} >
        <HeaderTitle data={dataHeader3} />
      </Grid>

      <Grid item xs={12} md={4.5} xl={4.5} >

        <Card>

          <SoftBox pt={3} px={3}>
            <SoftTypography variant="h6" fontWeight="medium">
              Yıllık İzin Geçmişi
            </SoftTypography>
            <SoftBox mt={1} mb={2}>
              <SoftTypography variant="button" color="text" fontWeight="regular">
                <SoftTypography display="inline" variant="body2" verticalAlign="middle">
                  <Icon sx={{ fontWeight: "bold", color: "error" }}>
                    label_important
                  </Icon>
                </SoftTypography>
                &nbsp;
                <SoftTypography variant="button" color="text" fontWeight="medium">
                  {izinRows?.length}
                </SoftTypography>{" "}
                İzin Kaydı
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <SoftBox p={2}>
            <Total name={"TOPLAM İZİNLER " + data?.user_info?.toplam_kullanılan} color="light" />
            <br />
            <br />
            {izinRows}
          </SoftBox>
        </Card>

      </Grid>
      <Grid item xs={12} md={4.5} xl={4.5} >
        <Card>
          <SoftBox pt={3} px={3}>
            <SoftTypography variant="h6" fontWeight="medium">
              Sağlık Raporu Geçmişi
            </SoftTypography>
            <SoftBox mt={1} mb={2}>
              <SoftTypography variant="button" color="text" fontWeight="regular">
                <SoftTypography display="inline" variant="body2" verticalAlign="middle">
                  <Icon sx={{ fontWeight: "bold", color: "error" }}>
                    label_important
                  </Icon>
                </SoftTypography>
                &nbsp;
                <SoftTypography variant="button" color="text" fontWeight="medium">
                  {raporizinRows?.length}
                </SoftTypography>{" "}
                İzin Kaydı
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <SoftBox p={2}>
            <Total name={"TOPLAM İZİNLER " + data?.toplam_rapor_gun} color="light" />
            <br />
            <br />
            {raporizinRows}
          </SoftBox>
        </Card>

      </Grid>

    </>

  );
}

export default Tables;
