import { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';


// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftBadge from "components/SoftBadge";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard React examples
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Soft UI Dashboard React icons
import Cube from "examples/Icons/Cube";
import Document from "examples/Icons/Document";
import Settings from "examples/Icons/Settings";
import { useSelector, useDispatch } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faCalendarMinus, faChartLine, faDownload, faExternalLinkSquareAlt, faFolderOpen, faGraduationCap, faHouse, faIdCard, faNoteSticky, faPersonWalkingLuggage, faUserClock, faUserTag } from '@fortawesome/free-solid-svg-icons'

// Soft UI Dashboard React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import burceMars from "assets/images/bruce-mars.jpg";
import burceMars2 from "assets/images/bilal.alemdaroglu.png";
import curved0 from "assets/images/curved-images/curved-trt-portal.png";

/* eslint-disable react/prop-types */

function Header(props) {

  const { id } = useParams();

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(3);

  useEffect(() => {
    if (props.permission && props.permission["AccPersonelListe"]) {
      setTabValue(0); 
    }
  }, [props.permission]);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (newValue) => {
    setTabValue(newValue);
    console.log(newValue);
    props.onChange(newValue);
  };

  const handleButtonClick = () => {
    window.location.href = `/cv/${props.data.id}`; 
  };

  
  
  const userId = useSelector(state => state.auth.userId);
  const AccSelf = (id+"" === userId+"")
  const AccPast = (props.data.eski_personel)

  return (
    <SoftBox position="relative">
      <DashboardNavbar absolute light data={props.data} />
      <SoftBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${curved0})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          backdropFilter: `saturate(200%) blur(30px)`,
          backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <SoftAvatar
              src={window.BASE_URL + props.data.personel_fotograf || burceMars2}
              alt="profile-image"
              variant="rounded"
              size="xxl"
              shadow="sm"
              icon="person"
              iconSize="70px !important"
              bgColor={"secondary"}
              

            />
          </Grid>

          <Grid item>
            <SoftBox height="100%" mt={0.5} lineHeight={1}>

              <SoftTypography variant="h5" fontWeight="medium">
                 {props.data.name ? props.data.name + " " + props.data.surname : "Personel Bulunamadı"}
              </SoftTypography>
              <SoftTypography variant="button" color="text" fontWeight="medium">
                {props.data.trt_arabi_unvan || props.data.trt_unvan ? props.data.trt_arabi_unvan + "/" + props.data.trt_unvan : "Erişim sağlanamıyor"}
              </SoftTypography>
              <br/>
              <br/>
              <SoftBadge
                  variant="gradient"
                  badgeContent={AccSelf ? "PROFİLİM" : (AccPast ? "ESKİ PERSONEL" : "PERSONEL")}
                  color={AccSelf ? "dark": (AccPast ? "light": "info")}
                  size="xs"
                  
                  container
                />
            </SoftBox>

          </Grid>

          {props.permission?.["AccPersonelListe"] ?<Grid item xs={12} md={1.8} lg={1.8} sx={{ ml: "auto", mt: "30px" }}>
            <SoftButton  id="printButton" onClick={handleButtonClick}  color="dark" variant="gradient" fullWidth>
              <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" color="dark">
                get_app
              </Icon> Özgeçmiş
            </SoftButton>
          </Grid>: ""}

          <Grid item xs={12} md={12} lg={12} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation}
                value={tabValue}
                onChange={(event, newValue) => handleSetTabValue(newValue)}
                sx={{ background: "transparent" }}
              >
                {props.permission?.["AccPersonelListe"] ? "" : <Tab value={3} label="Profil" icon={<Cube />} /> }
                {props.permission?.["AccPersonelListe"] ?  <Tab value={0} label="Genel" icon={<Cube />} /> : ""}
                {props.permission?.["AccPersonelListe"] ? <Tab value={1} label="Kurum" icon={<FontAwesomeIcon icon={faBuilding} />} />: ""}
                {props.permission?.["AccPersonelListe"] || AccSelf ? <Tab value={2} label="Eğitim" icon={<FontAwesomeIcon icon={faGraduationCap} />} /> : ""}
                
                
                

                {props.permission?.["AccIzinler"] || AccSelf ? <Tab value={4} label="İzinler" icon={<FontAwesomeIcon icon={faCalendarMinus} />} /> : ""}
                {props.permission?.["AccZimmet"] || AccSelf ?<Tab value={5} label="Zimmet" icon={<FontAwesomeIcon icon={faPersonWalkingLuggage} />} />: ""}
                {props.permission?.["AccPerformans"] ? <Tab value={6} label="Performans" icon={<FontAwesomeIcon icon={faUserTag} />} />: ""}
                {props.permission?.["AccDisiplin"] ? <Tab value={7} label="Disiplin" icon={<FontAwesomeIcon icon={faIdCard} />} />: ""}
                {props.permission?.["AccDegerlendirme"] ? <Tab value={8} label="Değerlendirme" icon={<FontAwesomeIcon icon={faNoteSticky} />} />: ""}
                {props.permission?.["AccPersonelListe"] ? <Tab value={9} label="Evrak" icon={<FontAwesomeIcon icon={faFolderOpen} />} /> : ""}
                {/* <Tab value= {10} label="Düzenle" icon={<Settings />} /> */}
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      </Card>
    </SoftBox>
  );
}

export default Header;
