import ProfileCardSpecial from "examples/ProfileCards/ProfileCardSpecial";
import ProfileCardSpecialIletisim from "examples/ProfileCards/ProfileCardSpecialIletisim";
import ProfileCardSpecialSaglik from "examples/ProfileCards/ProfileCardSpecialSaglik";
import ProfileCardKurum from "examples/ProfileCards/ProfileCardKurum";
import ProfileCardKurumSema from "examples/ProfileCards/ProfileCardKurumSema";
import ProfileCardEgitim from "examples/ProfileCards/ProfileCardEgitim";
import ProfileCardIzinler from "examples/ProfileCards/ProfileCardIzinler";
import ProfileCardTerfi from "examples/ProfileCards/ProfileCardTerfi";
import ProfileCardZimmet from "examples/ProfileCards/ProfileCardZimmet";
import ProfileCardZimmetBack from "examples/ProfileCards/ProfileCardZimmetBack";
import ProfileCardNot from "examples/ProfileCards/ProfileCardNot";
import ProfileCardDosya from "examples/ProfileCards/ProfileCardDosya";
import ProfileCardDisiplin from "examples/ProfileCards/ProfileCardDisiplin";
import ProfileCardDisiplinVerilen from "examples/ProfileCards/ProfileCardDisiplinVerilen";
import ProfileCardPerformans from "examples/ProfileCards/ProfileCardPerformansInews";

import PermissionNavbar from "examples/Navbars/PermissionNavbar";

import { useParams, useNavigate } from 'react-router-dom';

import { useState, useEffect } from "react";
import axios from "axios"


function Overview() {

    const { id } = useParams();
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`${window.BASE_URL}/profil/kisisel/${id}?format=json`, { withCredentials: true });
                setData(response.data);
            } catch (error) {
                setData("error");
            }
        };

        fetchUserData();
    }, [id]);


    if (data === "error") {
        return <PermissionNavbar />;
    }

    return (
        <>
            <ProfileCardSpecial data={data} />
            <ProfileCardSpecialIletisim data={data} />
            <ProfileCardSpecialSaglik data={data} />
        </>
    );
}

export default Overview;