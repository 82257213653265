// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";
import Table from "examples/Tables/Table";

import axios from 'axios';
import React, { useEffect, useState } from 'react';
// Soft UI Dashboard PRO React components
import SoftAvatar from "components/SoftAvatar";
import SoftBox from "components/SoftBox";
import SoftBadge from "components/SoftBadge";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";

// React Router
import { useNavigate } from "react-router-dom";

/* eslint-disable react/prop-types */

// Destek sayfası başlık bileşeni
import SupportHeader from "layouts/destek/header";

function Author({ id, image, name, email, tel, status }) {
    return (
        <a href={`/program/${id}`}>
            <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
                <SoftBox mr={2}>
                    <SoftAvatar src={""} size="xl" variant="rounded" icon="support_agent" bgColor={status === "KAPALI" ? "dark" : "secondary"} iconSize="50px !important" iconColor="#aaa !important" />
                </SoftBox>
                <SoftBox display="flex" flexDirection="column">
                    <SoftTypography variant="button" fontWeight="medium">
                        {name}
                    </SoftTypography>
                    <SoftTypography variant="caption" color="dark">
                        {email}
                    </SoftTypography>
                    <SoftTypography variant="caption" color="secondary">
                        {tel}
                    </SoftTypography>
                </SoftBox>
            </SoftBox>
        </a>
    );
}

function SupportPage() {
    const navigate = useNavigate(); // useNavigate hook'u tanımlandı

    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${window.BASE_URL}/destek/talepler?format=json`, { withCredentials: true });
                setData(response.data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);

    const tableRows = data?.talepler?.map(item => {
        return {
            "Başlık": <Author id={item.id} name={item?.baslik} image={window.BASE_URL + "/" + item.goruntu} tel={item.acilis_tarihi} status={item.durum} />,
            "Açıklama": <><Icon sx={{ fontWeight: "medium", color: "success" }}> person  </Icon>
                <SoftTypography variant="caption" color="text" fontWeight="medium">
                    <Link href={`/profile/${item.talep_eden_user?.id}`} >
                        {item.talep_eden_user?.name + " " + item.talep_eden_user?.surname}
                    </Link>
                </SoftTypography>
                <br />
                <SoftTypography variant="caption" color="text" fontWeight="small">{item.aciklama}</SoftTypography> </>,

            "Cevap": <>
                {item.gelistirici_aciklama && (
                    <>
                        <Icon sx={{ fontWeight: "medium", color: "success" }}>person</Icon>
                        <SoftTypography variant="caption" color="text" fontWeight="medium">
                            <Link href={`/profile/${item.gelistirici_user?.id}`}>
                                {`${item.gelistirici_user?.name} ${item.gelistirici_user?.surname}`}
                            </Link>
                        </SoftTypography>
                        <br />
                        <SoftTypography variant="caption" color="text" fontWeight="small">
                            {item.gelistirici_aciklama}
                        </SoftTypography>
                    </>
                )} </>,

            "Durum": <SoftBadge variant="gradient" badgeContent={item.durum} color={item.durum === "KAPALI" ? "dark" : "info"} size="xs"> </SoftBadge>,

            "Kanal": <SoftBadge variant="gradient" badgeContent={item.kanal_adi} color="dark" size="xs"> </SoftBadge>,

            "field_program": item.program_adi,
            "field_kanal": item.kanal_adi,

            "field_kategori": item.kanal_adi,

        };
    });

    const projectsTableData = {
        columns: [
            { name: "Başlık", align: "left", exportableField: `field_program`, searchable: true, searchField: [`field_program`] },
            { name: "Açıklama", align: "center" },
            { name: "Durum", align: "center" },
            { name: "Cevap", align: "center" },

        ],
        rows: tableRows
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox pt={3}>
                <SoftBox mb={3}>
                    <SupportHeader data={data} />
                </SoftBox>
                <SoftBox mb={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={3}>
                            <MiniStatisticsCard
                                bgColor="dark"
                                title={{ text: "Açık Talepler", fontWeight: "medium" }}
                                count={data?.ozet_bilgiler?.acik_ticket_sayisi + " Talep"}
                                icon={{ component: "support" }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <MiniStatisticsCard
                                bgColor="secondary"
                                title={{ text: "Sistem Sağlığı", fontWeight: "medium" }}
                                count="99 %"
                                icon={{ component: "help" }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <MiniStatisticsCard
                                bgColor="secondary"
                                title={{ text: "Ortalama Yanıt Süresi", fontWeight: "medium" }}
                                count="Hızlı"
                                icon={{ component: "support" }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <MiniStatisticsCard
                                bgColor="dark"
                                title={{ text: "Tüm Talepler", fontWeight: "medium" }}
                                count={data?.ozet_bilgiler?.toplam_ticket_sayisi + " Talep"}
                                icon={{ color: "info", component: "help" }}
                            />
                        </Grid>
                    </Grid>
                </SoftBox>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center" flexDirection="row-reverse" p={3} >
                    <SoftButton variant="outlined" color="secondary" onClick={() => navigate('/destek/talep-olustur')}>
                        yeni talep&nbsp;&nbsp;
                        <Icon>create</Icon>
                    </SoftButton>
                </SoftBox>
                <Grid item xs={12} md={12} xl={12} mb={3}>
                    <Card sx={{background:"#f8f9fa",boxShadow:"none"}}>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3} pb={0}>
                            <SoftTypography variant="h6">Destek Talepleri</SoftTypography>
                        </SoftBox>
                        <SoftBox
                            sx={{
                                "& .MuiTableRow-root:not(:last-child)": {
                                    "& td": {
                                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                            `${borderWidth[1]} solid ${borderColor}`,
                                    },
                                },
                            }}
                        >
                            <Table style={{background:"#f8f9fa"}} columns={projectsTableData.columns} rows={projectsTableData.rows} />
                        </SoftBox>
                    </Card>

                </Grid>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
}

export default SupportPage;
