
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Images
import wavesWhite from "assets/images/shapes/waves-white.svg";
import rocketWhite from "assets/images/illustrations/network-trt.png";

import { makeStyles } from '@mui/styles';
/* eslint-disable react/prop-types */

const useStyles = makeStyles({
  imgStyle: {
    filter: 'brightness(0.5) saturate(1)',
  },
});

function BuildByDevelopers({title,text}) {
  const classes = useStyles();
  return (
    <Card>
      <SoftBox p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <SoftBox display="flex" flexDirection="column" height="100%">
              <SoftBox pt={1} mb={0.5}>
                {/* <SoftTypography variant="body2" color="text" fontWeight="medium">
              Grafiklerle Desteklenmiş
              </SoftTypography> */}
              </SoftBox>
              <SoftTypography variant="h5" fontWeight="bold" gutterBottom>
                {title ? title : "Sayfaya Erişilemiyor" }
              </SoftTypography>
              <SoftBox mb={6}>
                <SoftTypography variant="body2" color="text">
                  {text ? text : "Bu sayfaya erişim sağlanamıyor. Yeterli yetkiniz olmayabilir veya sayfa mevcut olmayabilir" }
                </SoftTypography>
              </SoftBox>

              <SoftTypography
                component="a"
                href="/kurum"
                variant="button"
                color="text"
                fontWeight="medium"
                sx={{
                  mt: "auto",
                  mr: "auto",
                  display: "inline-flex",
                  alignItems: "center",
                  cursor: "pointer",

                  "& .material-icons-round": {
                    fontSize: "1.125rem",
                    transform: `translate(2px, -0.5px)`,
                    transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                  },

                  "&:hover .material-icons-round, &:focus  .material-icons-round": {
                    transform: `translate(6px, -0.5px)`,
                  },
                }}
              >
                Anasayfaya Dön
                <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={12} lg={5} sx={{ position: "relative", ml: "auto" }}>
            <SoftBox
              height="100%"
              display="grid"
              justifyContent="center"
              alignItems="center"
              bgColor="white"
              borderRadius="lg"
              variant="gradient"
              style={{ background: 'linear-gradient(310deg, #f5f5f5, #ffffff)' }}
            >
              <SoftBox
                component="img"
                src={wavesWhite}
                alt="waves"
                display="block"
                position="absolute"
                left={0}
                width="100%"
                height="100%"

              />
              <SoftBox component="img" src={rocketWhite} alt="rocket" width="100%" pt={3} className={classes.imgStyle} />
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

export default BuildByDevelopers;
