// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Link from '@mui/material/Link';

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import TimelineItem from "examples/Timeline/TimelineItem";
import { useSelector } from 'react-redux';
// import { Resume } from '@welovedevs/react-ultimate-resume';

/* eslint-disable react/prop-types */

const Index = ({ data }) => {
    const userId = useSelector(state => state.auth.userId);
    const username = useSelector(state => state.auth.username);
    const userRole = useSelector(state => state.auth.userRole);

    // Veri kontrolü yapılmalı
    const notlar = data?.data?.dosyalar || [];

    const notRows = notlar.map(not => (
        <Grid item xs={12} md={6} xl={6} key={not.id}>
            <Card className="h-100">
                <SoftBox pt={3} px={3}>
                    <SoftTypography variant="h6" fontWeight="medium">
                        {not.title}
                    </SoftTypography>
                    <SoftBox mt={1} mb={2}>
                        <SoftTypography variant="button" color="text" fontWeight="regular">
                            <SoftTypography display="inline" variant="body2" verticalAlign="middle">
                                <Icon sx={{ fontWeight: "bold", color: "error" }}>label_important</Icon>
                            </SoftTypography>
                            &nbsp;
                            {not.yuklenme_tarihi}
                        </SoftTypography>
                    </SoftBox>
                </SoftBox>
                <SoftBox p={2}>
                    <TimelineItem
                        color={not.terfi_tenzil === "alindi" ? "primary" : "info"}
                        icon={not.terfi_tenzil === "alindi" ? "ChatBubble" : "get_app"}
                        description={not.notu}
                        title={
                            <Link href={`${not.dosya}`} download>
                                {not.dosya}
                            </Link>
                        }
                        dateTime={not.zimmet_tarih}
                    />
                </SoftBox>
            </Card>
        </Grid>
    ));

    return (
        <Grid container spacing={3}>
            {notRows.length > 0 ? notRows : (
                <SoftBox pt={9} px={9}>
                    <SoftTypography variant="h6" fontWeight="medium" color="text">
                        Henüz yüklenmiş dosya yok.
                    </SoftTypography>
                </SoftBox>
            )}
        </Grid>
    );
}

export default Index;
