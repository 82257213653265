import PropTypes from "prop-types";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import SoftInput from "components/SoftInput";

function SoftDatePicker({ input, disabled, ...rest }) {
  return (
    <Flatpickr
      {...rest}
      // Flatpickr bileşeninde direkt bir disable kullanımı yok. input alanını disabled yapıyoruz.
      render={({ defaultValue }, ref) => (
        <SoftInput
          {...input}
          defaultValue={defaultValue}
          inputRef={ref}
          disabled={disabled}  // Sadece input için disabled ayarı
        />
      )}
    />
  );
}

// Setting default values for the props of SoftDatePicker
SoftDatePicker.defaultProps = {
  input: {},
  disabled: false,  // Varsayılan olarak false tanımlıyoruz
};

// Typechecking props for the SoftDatePicker
SoftDatePicker.propTypes = {
  input: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool,  // disabled prop'u typechecking için eklendi
};

export default SoftDatePicker;
