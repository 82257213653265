/* eslint-disable react/prop-types */

import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftAvatar from "components/SoftAvatar";

// Soft UI Dashboard React examples
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

import axios from "axios"

import { useNavigate } from 'react-router-dom';
import { getFilteredRoutes } from "routes";
// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Soft UI Dashboard React context
import {
  useSoftUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

// Images
import team2 from "assets/images/team-2.jpg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import SoftBadge from "components/SoftBadge";

function DashboardNavbar({ absolute, light, isMini, data }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [openMenuAssignment, setOpenMenuAssignment] = useState(false);
  const [openMenuNotification, setOpenMenuNotification] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);

  const navigate = useNavigate();


  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleOpenMenuAssignment = (event) => setOpenMenuAssignment(event.currentTarget);
  const handleOpenMenuNotification = (event) => setOpenMenuNotification(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleCloseMenuAssignment = () => setOpenMenuAssignment(false);
  const handleCloseMenuNotification = () => setOpenMenuNotification(false);

  const handleProfileCloseMenu = (path) => {
    setOpenMenu(false);
    setOpenMenuAssignment(false);
    navigate(path);
  };


  const logout = async () => {
    try {
      const response = await axios.post(`${window.BASE_URL}/api/logout`, {}, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        }
      });

      window.location.href = '/authentication/sign-in'
    } catch (error) {
      console.error('Logout failed:', error);
      // Logout başarısızsa burada hata işleme yapabilirsiniz.
    }
  };

  const handleLogoutMenu = () => {
    logout();
    setOpenMenu(false);
    setOpenMenuAssignment(false);
  };

  const userId = useSelector(state => state.auth.userId);
  const username = useSelector(state => state.auth.username);
  const userRole = useSelector(state => state.auth.userRole);
  const userPhoto = useSelector(state => state.auth.userPhoto);

  const tasksCount = useSelector(state => state.auth.tasks);
  let totalTasks = 0;
  for (let key in tasksCount) {
    if (key != "birtday_count")
    totalTasks += tasksCount[key];
  }

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >

      <NotificationItem
        color="light"
        image={
          userPhoto == "-" ?
            <Icon fontSize="small" sx={{ color: ({ palette: { dark } }) => dark.main }}>
              person
            </Icon> : <SoftAvatar
              src={window.BASE_URL + userPhoto}
              alt="profile-image"
              variant="rounded"
              size="sm"
              shadow="sm"
              icon="account_box"
              iconSize="20px !important"

            />
        }
        title={[username, userRole]}
        date="Profili Görüntüle"
        icon="person"
        onClick={() => handleProfileCloseMenu(`/profile/${userId}`)}
      />

      <NotificationItem
        color="light"
        image={
          <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
            settings
          </Icon>
        }
        title={["Hesap Ayarları", "", "Hesap ayarlarını yap"]}
        date="Hesap Ayarlarını Yap"
        icon="settings"
        onClick={() => handleProfileCloseMenu(`/ayarlar`)}
      />
      <NotificationItem
        color="dark"
        image={
          <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
            logout
          </Icon>
        }
        title={["Çıkış Yap", "Hesaptan çıkış yap"]}
        date=""
        icon=""
        onClick={handleLogoutMenu}
      />
    </Menu>
  );


  const renderMenuNotification = () => (
    <Menu
      anchorEl={openMenuNotification}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenuNotification)}
      onClose={handleCloseMenuNotification}
      sx={{ mt: 2 }}
    >



      {tasksCount.birtday_count == 0
        ?
        <NotificationItem
          color="light"
          image={

            <Icon fontSize="small" sx={{ color: ({ palette: { dark } }) => dark.main }}>
              notifications
            </Icon>


          }
          title={["Bildiriminiz Bulunmuyor"]}
          date="Menüyü Kapat"
          icon="visibility_off"
          onClick={() => handleCloseMenuNotification()}
        />
        : ""}

      {tasksCount.birtday_count > 0
        ?
        <NotificationItem

          color="light"
          image={
            <>
              <Icon fontSize="small" sx={{ color: ({ palette: { dark } }) => dark.main }}>
                cake
              </Icon>
              <SoftBadge variant="gradient" badgeContent={tasksCount.birtday_count} color="secondary" size="sm" style={{ position: "absolute", top: "5px", height: "16px", width: "15px" }} />
            </>
          }
          title={["Personel Doğum Günü"]}
          date="Detayları İncele"
          icon="class"
          onClick={() => handleProfileCloseMenu(`/insan-kaynaklari/personel-listesi`)}
        />
        : ""}


      



    </Menu>
  );

  const renderMenuAssignment = () => (
    <Menu
      anchorEl={openMenuAssignment}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenuAssignment)}
      onClose={handleCloseMenuAssignment}
      sx={{ mt: 2 }}
    >



      {totalTasks == 0
        ?
        <NotificationItem
          color="light"
          image={

            <Icon fontSize="small" sx={{ color: ({ palette: { dark } }) => dark.main }}>
              assignment
            </Icon>


          }
          title={["Göreviniz Bulunmuyor"]}
          date="Menüyü Kapat"
          icon="visibility_off"
          onClick={() => handleCloseMenuAssignment()}
        />
        : ""}

      {tasksCount.misdata_count > 0
        ?
        <NotificationItem

          color="light"
          image={
            <>
              <Icon fontSize="small" sx={{ color: ({ palette: { dark } }) => dark.main }}>
                perm_media
              </Icon>
              <SoftBadge variant="gradient" badgeContent={tasksCount.misdata_count} color="info" size="sm" style={{ position: "absolute", top: "5px", height: "16px", width: "15px" }} />
            </>
          }
          title={["Veri Düzenlemesi"]}
          date="Detayları İncele"
          icon="class"
          onClick={() => handleProfileCloseMenu(`/insan-kaynaklari/personel-listesi`)}
        />
        : ""}


      {tasksCount.puanlama_count > 0
        ?
        <NotificationItem

          color="light"
          image={
            <>
              <Icon fontSize="small" sx={{ color: ({ palette: { dark } }) => dark.main }}>
                star
              </Icon>
              <SoftBadge variant="gradient" badgeContent={tasksCount.puanlama_count} color="info" size="sm" style={{ position: "absolute", top: "5px", height: "16px", width: "15px" }} />
            </>
          }
          title={["Personel Değerlendirmesi"]}
          date="Detayları İncele"
          icon="class"
          onClick={() => handleProfileCloseMenu(`/puanlama/puanlama-liste`)}
        />
        : ""}


      {tasksCount.gorevlendirme_count > 0
        ?
        <NotificationItem

          color="light"
          image={
            <>
              <Icon fontSize="small" sx={{ color: ({ palette: { dark } }) => dark.main }}>
                flight_takeoff
              </Icon>
              <SoftBadge variant="gradient" badgeContent={tasksCount.gorevlendirme_count} color="info" size="sm" style={{ position: "absolute", top: "5px", height: "16px", width: "15px" }} />
            </>
          }
          title={["Görevlendirme Talebi"]}
          date="Detayları İncele"
          icon="class"
          onClick={() => handleProfileCloseMenu(`/gorevlendirme/gorev-takip`)}
        />
        : ""}



    </Menu>
  );

  const updateBreadcrumbData = (data, routes, route) => {
    const updatedData = { ...data };

    routes.forEach(r => {
      if (r.route === `/${route[0]}`) {
        if (r.name) updatedData.path = r.name;
        if (r.children) {
          r.children.forEach(child => {
            if (child.route === `/${route.join('/')}` && child.name) {
              updatedData.route = child.name;
            }
          });
        }
      }
    });

    return updatedData;
  };




  const updatedRoutes = updateBreadcrumbData(data, getFilteredRoutes("All"), route);

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <SoftBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} data={updatedRoutes} />
        </SoftBox>
        {isMini ? null : (
          <SoftBox sx={(theme) => navbarRow(theme, { isMini })}>
            {/* <SoftBox pr={1}>
              <SoftInput
                placeholder="TRT arama"
                icon={{ component: "search", direction: "left" }}
              />
            </SoftBox> */}
            <SoftBox color={light ? "white" : "inherit"}>
              {/* <Link to="/authentication/sign-in">
                <IconButton sx={navbarIconButton} size="small">
                  <Icon
                    sx={({ palette: { dark, white } }) => ({
                      color: light ? white.main : dark.main,
                    })}
                  >
                    account_circle
                  </Icon>

                </IconButton>
              </Link> */}
              <IconButton
                size="small"
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon className={light ? "text-white" : "text-dark"}>
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>

              <IconButton
                size="small"
                color="inherit"
                sx={navbarIconButton}
                onClick={handleOpenMenuNotification}
                style={{ marginRight: "10px" }}
              >
                <Icon>notifications</Icon>
                {0 > 0 ? <SoftBadge variant="gradient" badgeContent={totalTasks} color="info" size="sm" style={{ position: "absolute", left: "6px", top: "-9px", height: "16px", width: "15px" }} /> : ""}
              </IconButton>
              {renderMenuNotification()}

              <IconButton
                size="small"
                color="inherit"
                sx={navbarIconButton}
                onClick={handleOpenMenuAssignment}
                style={{ marginRight: "10px" }}
              >
                <Icon>assignment</Icon>
                {totalTasks > 0 ? <SoftBadge variant="gradient" badgeContent={totalTasks} color="info" size="sm" style={{ position: "absolute", left: "6px", top: "-9px", height: "16px", width: "15px" }} /> : ""}
              </IconButton>
              {renderMenuAssignment()}
              <IconButton
                size="small"
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon size={70} className={light ? "text-white" : "text-dark"}>person</Icon>
                <SoftTypography color={light ? "text-white" : "text"} variant="caption" fontWeight="medium">Profil</SoftTypography>
              </IconButton>
              {renderMenu()}
            </SoftBox>
          </SoftBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
